import React, { useState, useEffect, useContext } from "react";
import { getInitials } from "../helpers";
import classNames from "classnames";
import {
  Box,
  Tabs,
  Tab,
  Badge,
  CircularProgress,
  Stack,
} from '@mui/material';
import { useQuery } from "@apollo/client";
import { GET_TEAMS } from "../apollo/queries/getTeams";
import { UserContext } from "../context/UserContext";
import TeamAssetList from "../components/TeamAssetList/TeamAssetList";
import AssetReferralTable from "../components/AssetReferralTable/AssetReferralTable";
import TasksAndTaskFlows from "./TasksAndTaskFlows";
import Button from "../styled/Button/Button";
import { useTheme } from "@emotion/react";
import Typography from "../styled/Typography/Typography";

const hidden = {
  display: 'none',
  height: '100%',
}

const defaultStyle = {
  height: '100%',
}

export default function TeamAssets({ client }) {
  const [numTasks, setNumTasks] = useState(null);
  const [numReferrals, setNumReferrals] = useState(null);
  const [numAssets] = useState(null);
  const [selected, setSelected] = useState(null);
  const { setTeamID, setPortfolioID, user, setUser } = useContext(UserContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [teams, setTeams] = useState([]);

  const theme = useTheme();

  const styles = {
    selected: {
      background: theme.themeColor.bodySecondary
    },
    innerBox: {
      background: theme.themeColor.bodyTetriary
    }
  }

  const { loading, data, error } = useQuery(GET_TEAMS, {
    variables: {
      input: {
        options: {
          memberships: { some: { userID: parseInt(user.id) } }
        }
      }
    }
  });

  useEffect(async () => {
    if (data?.findTeams.length > 0) {
      const validTeams = data.findTeams.filter((team) => {
        const hasValidPortfolios = team.portfolios?.some((portfolio) => !portfolio.deleted) ?? false;
        const hasAssets = team.assets?.length > 0;
        return hasValidPortfolios || hasAssets;
      });

      if (validTeams.length > 0) {
        const teams_team = [];
        validTeams.forEach((team) => {
          team.portfolios.forEach((portfolio) => {
            if (!portfolio.deleted) teams_team.push({ ...team, portfolio });
          });
        });

        setTeams(teams_team);
        setTeamID(teams_team[0]?.id)
        setPortfolioID(teams_team[0]?.portfolio?.id)
        setSelected(teams_team[0]);
      } else {
        setUser({ ...user, noTeamsOrPortfolio: true })
      }
    } else if (data?.findTeams.length === 0) {
      setUser({ ...user, noTeamsOrPortfolio: true })
    }
  }, [data]);

  const handleChange = (_, newValue) => { setSelectedTab(newValue) };

  if (user?.noTeamsOrPortfolio) return (
    <Typography component="h1" variant="h3" align="center" mt={50}>
      You do not currently belong to a team or portfolio.
    </Typography>
  )
  if (selected === null && !error) return null;
  if (loading) return <CircularProgress />;

  if (!teams.length) {
    return (
      <Typography component="h1" variant="h3" align="center" mt={50}>
        You do not currently belong to a team or portfolio.
      </Typography>
    )
  }

  return (
    <Box sx={{ m: 3 }}>
      <Stack sx={{height: '80vh'}}>
        <div className="side-tabs-layout">
          <div className="side-tabs">
            {teams.map((team) => {
              return (
                <Button
                  aria-label={team.portfolio.name}
                  title={team.portfolio.name}
                  className={classNames(
                    "side-tab",
                      `${selected === team.id ? "selected" : ""}`
                  )}
                  key={team.id}
                  style={selected === team.id ? styles.selected : {}}
                  onClick={() => {
                    setSelected(team);
                    setTeamID(team.id);
                  }}
                >
                  <div style={styles.innerBox} className="inner-box">
                    {getInitials(team.name)}
                    {numAssets && <Badge color="primary" badgeContent={numAssets} />}
                  </div>
                </Button>
              );
            })}
          </div>
          <Box sx={{ height: '80vh', border: `1px solid ${theme.themeColor.sectionStroke}`, display:'flex', 'flex-direction': 'column' }}>
            <Tabs value={selectedTab} onChange={handleChange}>
              <Tab label="Assets" />
              <Tab
                label={
                  <div>
                      Tasks{" "}
                    {numTasks > 0 && <Badge sx={{ml: 2}} badgeContent={numTasks} color="primary" />}
                  </div>
                }
              />
              <Tab
                label={
                  <div style={{width: "300px"}}>
                      Referrals{" "}
                    {numReferrals > 0 && <Badge sx={{ml: 2, mr: 1}} badgeContent={numReferrals} color="primary" />}
                  </div>
                }
              />
            </Tabs>

            <div style={selectedTab !== 0 ? hidden : defaultStyle}>
              <TeamAssetList team={selected} selectedTab={selectedTab} client={client} />
            </div>
            <div style={selectedTab !== 1 ? hidden : defaultStyle}>
              <TasksAndTaskFlows selectedTab={selectedTab} setNumTasks={setNumTasks} isTeam={true}/>
            </div>
            <div style={selectedTab !== 2 ? hidden : defaultStyle}>
              <AssetReferralTable teamID={selected?.id} user={user} setNumReferrals={setNumReferrals} />
            </div>
          </Box>
        </div>
      </Stack>
    </Box>
  );
}