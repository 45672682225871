import { gql } from "@apollo/client";

export const CLOSE_MILESTONE_MUTATION = gql`
  mutation CloseMilestone(
    $asset: updateAssetInput!
    $closeMilestoneEvent: createEventInput!
    $openMilestoneEvent: createEventInput!
  ) {
    updateAsset(input: $asset) {
      id
    }

    openMilestone: createEvent(input: $closeMilestoneEvent) {
      id
    }

    closeMilestone: createEvent(input: $openMilestoneEvent) {
      id
    }
  }
`;
