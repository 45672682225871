import { gql } from "@apollo/client";

export const GET_TEAMS_BY_MANAGER = gql`
  query Query {
    findTeamsManagedByMe {
      id
      deleted
      name
      memberships {
        createdAt
        isBillingAdmin
        user {
          id
          blocked
          firstName
          lastName
          memberStatus
          email
        }
      }
    }
  }
`;