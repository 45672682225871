import React, { useContext, useEffect, useState, useRef } from 'react';
import { useMutation } from "@apollo/client";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { rgba } from "emotion-rgba";
import Modal from "../../styled/Modal/Modal";
import Typography from "../../styled/Typography/Typography";
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Badge, IconButton, Avatar } from "@mui/material";
import Button from "../../styled/Button/Button";
import AddIcon from '@mui/icons-material/Add';
import Chip from '../../styled/Chip/Chip';
import { MessageContext } from '../../context/MessageContext';
import { GET_TASK_FLOW_TEMPLATES_QUERY } from '../../apollo/queries/getTaskFlowTemplatesQuery';
import { UserContext } from '../../context/UserContext';
import { UPDATE_TASK_FLOW_TEMPLATE_MUTATION } from '../../apollo/mutations/updateTaskFlowTemplateMutation';
import Checkbox from '../../styled/Checkbox/Checkbox';
import { JUDICIAL, NON_JUDICIAL, ALL_STATES, GENERIC_JUDICIAL, GENERIC_NONJUDICIAL } from '../../utilities/genericSteps';
import { CREATE_TASK_FLOW_TEMPLATE_MUTATION } from '../../apollo/mutations/createTaskFlowTemplateMutation';

const Header = styled(Box)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
  height: 10%;
  color: ${({theme}) => theme.themeColor.bodySecondary};
  margin: 0 -25px;
  padding: 25px;
`;
const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 1rem;
  max-height: 60vh;
  flex: 1;
`;
const SubtaskRow = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem;
  background-color: ${({theme}) => theme.themeColor.backgroundBody};
  color: ${({theme}) => theme.themeColor.bodyMain};
  font-size: 0.9rem;
`;
const Bottom = styled(Box)`
  margin: 5vh 0 0 0 ;
  border-top: 1px solid #ccc;
  height: 75px;
  border-top: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
  display: flex;
  align-items: center;
  padding: 25px;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: space-around;
`;

const EditTaskFlow = ({ taskFlow, show, setShow }) => {
  const { addMessage } = useContext(MessageContext);
  const theme = useTheme();
  const scrollRef = useRef(null);
  const allAssignments = ['Portfolio Manager', 'Referred Law Firm'];

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [newTaskName, setNewTaskName] = useState("");
  const [budgetDays, setBudgetDays] = useState("");
  const [defaultAssignment, setDefaultAssignment] = useState("Portfolio Manager");
  const [createOnMilestone, setCreateOnMilestone] = useState(false);
  const { portfolioID } = useContext(UserContext);
  const [states, setStates] = useState([]);
  const [steps, setSteps] = useState([]);
  const [category, setCategory] = useState([]);
  const [subtasks, setSubtasks] = useState([]);

  const [editingSubtaskIndex, setEditingSubtaskIndex] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const [tempValue, setTempValue] = useState("");

  useEffect(() => {
    if (show) {
      setOpen(show);
    } else setOpen(false);

    setFormData({ ...taskFlow });
    setStates(taskFlow.states || []);
    setCreateOnMilestone(taskFlow.createOnMilestone);
    setCategory(taskFlow.processType.split(', '));
    setSubtasks(taskFlow.subtasks || []);
  }, [taskFlow, show]);

  useEffect(() => {
    if (states.length > 0) {
      if (JUDICIAL.includes(states[0])) setSteps(GENERIC_JUDICIAL);
      else if (NON_JUDICIAL.includes(states[0])) setSteps(GENERIC_NONJUDICIAL);
    }
  }, [states]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [subtasks]);

  const [updateTaskFlowTemplate, { loading }] = useMutation(UPDATE_TASK_FLOW_TEMPLATE_MUTATION, {
    refetchQueries: [
      { query: GET_TASK_FLOW_TEMPLATES_QUERY, variables: { portfolioID } },
    ],
  });

  const [createTaskFlowTemplate] = useMutation(CREATE_TASK_FLOW_TEMPLATE_MUTATION, {
    refetchQueries: [
      { query: GET_TASK_FLOW_TEMPLATES_QUERY, variables: { portfolioID } },
    ],
  });

  const onCancel = () => {
    setShow(false);
  }

  const handleChange = (event) => {
    const {name, value} = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleCheckbox = () => {
    setCreateOnMilestone(!createOnMilestone);
    setFormData((prevState) => ({
      ...prevState,
      createOnMilestone: !createOnMilestone,
    }));
  }

  const handleNewTaskNameChange = (event) => {
    const { value } = event.target;
    setNewTaskName(value);
  }
  const handleBudgetDaysChange = (event) => {
    const { value } = event.target;
    setBudgetDays(parseInt(value));
  }
  const handleDefaultAssignmentChange = (event) => {
    const { value } = event.target;
    setDefaultAssignment(value);
  }
  const onChangeCategory = (event) => {
    const { value } = event.target;
    setCategory(value);
  }
  const handleSubmit = async () => {
    try {
      const isGlobal = taskFlow.global

      const resp = isGlobal
        ? await createTaskFlowTemplate({
          variables: { input: {
            name: formData.name,
            description: formData.description,
            subtasks: subtasks,
            portfolio: portfolioID ? portfolioID : null,
            createOnMilestoneID: formData.createOnMilestoneID,
            createOnMilestone: formData.createOnMilestone,
            states,
            subcategory: taskFlow.subcategory,
            version: taskFlow.version + 1,
            global: true,
            showToEveryone: true,
            processType: taskFlow.processType,
            originalID: isGlobal && taskFlow.originalID
              ? parseInt(taskFlow.originalID)
              : parseInt(formData.id)
          }}
        })
        : await updateTaskFlowTemplate({
          variables: { input: {
            where: { id: formData.id },
            data: { 
              name: formData.name,
              description: formData.description,
              subtasks: subtasks,
              portfolioID,
              createOnMilestoneID: formData.createOnMilestoneID,
              createOnMilestone: formData.createOnMilestone,
              states,
              processType: category.join(', ')
            } 
          }},
        });

      if ('errors' in resp) {
        addMessage({ message: "Something went wrong while updating the task.", type: "error"});
      } else {
        addMessage({ message: 'Task flow template updated.' });
        setShow(false);
      }

    } catch {
      addMessage({ message: 'Something went wrong. Try again later!', type: 'error' });
    }
  }

  const onAddNewSubTask = () => {
    if (!newTaskName || newTaskName.length === 0) {
      addMessage({type: 'error', message: 'You need to set the subtask name.'});
      return;
    }

    const newSubtasks = [...subtasks, {
      name: newTaskName,
      order: subtasks.length + 1,
      responsible: defaultAssignment,
      budgetDays: budgetDays ? parseInt(budgetDays) : 7,
    }]

    setSubtasks(newSubtasks);
    setNewTaskName("");
    setBudgetDays("");
    setDefaultAssignment("Portfolio Manager");
  }

  const onDeleteTask = (taskToRemove) => {
    const copy = [...subtasks].map((task) => ({...task}));
    const filtered = copy.filter((task) => task.name !== taskToRemove);
    
    if (filtered.length)
      filtered.forEach((task, idx) => task.order = idx + 1);
    
    setSubtasks(filtered);
  }

  const onChangeStates = (event) => {
    const { value } = event.target;

    if (value[value.length - 1] === "all") {
      setStates(states.length === ALL_STATES.length ? [] : ALL_STATES);
      return;
    }
    setStates(typeof value === 'string' ? value.split(',') : value);
    setFormData((prev) => ({
      ...prev,
      states: states.length === ALL_STATES.length ? [] : ALL_STATES
    }))
  }

  const onChangeSelectedStep = (event) => {
    const { value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      createOnMilestoneID: value,
    }));
  }

  const handleChipClick = (field, index) => {
    setEditingField(field);
    setEditingSubtaskIndex(index);
    setTempValue(subtasks[index][field] || "");
  };
  const handleTempValueChange = (e) => {
    if (e.target.name === 'default-assignment') {
      const updatedSubtasks = [...subtasks].map((task) => ({...task}));
      updatedSubtasks[editingSubtaskIndex]['responsible'] = e.target.value;
      setSubtasks(updatedSubtasks);
      setEditingSubtaskIndex(null);
      setEditingField(null);
      setTempValue("");
      return;
    }

    setTempValue(e.target.value);
  };
  const saveEdit = () => {
    const updatedSubtasks = [...subtasks].map((task) => ({...task}));
    updatedSubtasks[editingSubtaskIndex][editingField] = tempValue;
    
    setSubtasks(updatedSubtasks);
    setEditingSubtaskIndex(null);
    setEditingField(null);
    setTempValue("");
  };

  return (
    <Modal open={open} onClose={onCancel} theme={theme} height="88vh" width="70vw" sx={{overflowY: 'hidden'}}>
      <Header theme={theme}>
        <Typography fontSize="1.5em">Update Task Flow Template</Typography>
      </Header>
      <Body sx={{ mt: 2 }} ref={scrollRef}>
        <FormControl>
          <TextField
            name="flow-name"
            variant='outlined'
            label="Flow Name"
            onChange={handleChange}
            value={formData.name}
            id="name"
            sx={{ maxHeight: '50px'}}
          />
          <TextField
            name="flow-description"
            variant='outlined'
            label="Flow Description"
            onChange={handleChange}
            value={formData.description}
            id="description"
            sx={{ mt: '10px', maxHeight: '50px' }}
            multiline={5}
          />
          <FormControl
            sx={{mt: '15px'}}
          >
            <InputLabel id="label-category-select">Category</InputLabel>
            <Select
              labelId="label-category-select"
              label="Category"
              onChange={onChangeCategory}
              value={category}
              multiple
            >
              <MenuItem value="FORECLOSURE">Foreclosure</MenuItem>
              <MenuItem value="BANKRUPTCY">Bankruptcy</MenuItem>
              <MenuItem value="LOSS MITIGATION">Loss Mitigation</MenuItem>
              <MenuItem value="OTHER">Other</MenuItem>
            </Select>
          </FormControl>
          <Checkbox className={"auto-taskflow-checkbox"} label="Create automatically when milestone is reached" checked={createOnMilestone} onChange={handleCheckbox} />
          {createOnMilestone && (
            <Stack spacing={2}>
              <FormControl>
                <InputLabel id="label-state-select">States</InputLabel>
                <Select
                  labelId="label-state-select"
                  label="State"
                  multiple
                  value={states}
                  onChange={onChangeStates}
                >
                  <MenuItem value="all" key="select-all-states">Select All</MenuItem>
                  {ALL_STATES.map((state) => (
                    <MenuItem key={state} value={state}>{state}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="label-milestone-select">Milestone</InputLabel>
                <Select
                  labelId="label-milestone-select"
                  label="Milestone"
                  onChange={onChangeSelectedStep}
                  value={formData.createOnMilestoneID}
                  disabled={steps.length === 0}
                >
                  {steps.map((step) => (
                    <MenuItem disabled={step.disabled} key={step.stepID} value={step.stepID}>{step.stepID} - {step.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          )}
          <Typography fontSize="20px">Subtasks</Typography>
          <Box sx={{ mt: 1, mb: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <TextField
              name="subtask-name"
              variant='outlined'
              label="New subtask name"
              onChange={handleNewTaskNameChange}
              value={newTaskName}
              id="name"
              sx={{ mr: '10px', mb: '10px', maxHeight: '50px', width: "50%" }}
            />
            <TextField
              name="budget-days"
              variant="outlined"
              label="Budget Days"
              value={budgetDays ? budgetDays : 7}
              onChange={handleBudgetDaysChange}
              id="budget-days"
              type="number"
            />
            <FormControl variant="outlined">
              <InputLabel id="my-label">Default Assignment</InputLabel>
              <Select
                id='default-assignment-dropdown'
                value={defaultAssignment ? defaultAssignment : 'Portfolio Manager'}
                name='default-assignment'
                label="Default Assignment"
                onChange={handleDefaultAssignmentChange}
              >
                {allAssignments &&
                allAssignments.map((item, i) => {
                  return (
                    <MenuItem
                      key={`${item}-${i}`}
                      value={item.length ? item : 'Portfolio Manager'}
                      label="Default Assignment"
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Button
              id="add-subtask-button"
              sx={{ width: '50px' }}
              onClick={onAddNewSubTask}
            >
              <AddIcon />
            </Button>
          </Box>
          <Stack direction="column" spacing={2}>
            {subtasks && subtasks.map((task, idx) => (
              <SubtaskRow key={`${task.name}-${idx}`}>
                <Badge sx={{ml: 1.5, mr: 3, fontFamily: 'IBM Plex Mono'}} badgeContent={task.order} color="secondary" />
                {editingSubtaskIndex === idx && editingField === 'name' ? (
                  <TextField
                    sx={{ width: '50%', mr: '10px' }}
                    value={tempValue}
                    onChange={handleTempValueChange}
                    onKeyDown={(e) => e.key === 'Enter' && saveEdit()}
                    onBlur={saveEdit}
                    autoFocus
                  />
                ) : (
                  <Chip
                    sx={{ width: '50%', maxWidth: '50%', mr: '10px' }}
                    key={`${task.order}-${idx}`}
                    label={task.name}
                    onClick={() => handleChipClick('name', idx)}
                  />
                )}
                {editingSubtaskIndex === idx && editingField === 'budgetDays' ? (
                  <TextField
                    sx={{ width: '10%', mr: '10px' }}
                    value={tempValue}
                    onChange={handleTempValueChange}
                    onKeyDown={(e) => e.key === 'Enter' && saveEdit()}
                    onBlur={saveEdit}
                    autoFocus
                    type="number"
                  />
                ) : (
                  <Chip
                    sx={{ width: '5%', maxWidth: '10%', mr: '10px' }}
                    key={`${task.budgetDays}-${idx}`}
                    label={task.budgetDays}
                    onClick={() => handleChipClick('budgetDays', idx)}
                  />
                )}
                {editingSubtaskIndex === idx && editingField === 'responsible' ? (
                  <FormControl variant="outlined">
                    <InputLabel id="my-label">Default Assignment</InputLabel>
                    <Select
                      id="default-assignment-dropdown"
                      labelId="my-label"
                      label="Default Assignment"
                      value={defaultAssignment ?? 'Portfolio Manager'}
                      name="default-assignment"
                      onChange={handleTempValueChange}
                    >
                      {allAssignments?.map((item, i) => (
                        <MenuItem
                          key={`${item}-${i}`}
                          value={item?.length ? item : 'Portfolio Manager'}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Chip
                    sx={{ width: '20%', maxWidth: '20%', mr: '10px' }}
                    key={`${task.responsible}-${idx}`}
                    label={task.responsible}
                    onClick={() => handleChipClick('responsible', idx)}
                  />
                )}

                <IconButton sx={{width: '20px'}}onClick={() => onDeleteTask(task.name)} disabled={loading} title="Delete Subtask">
                  <Avatar sx={{
                    bgcolor: rgba(theme.themeColor.brandPrimaryRed, 0.1),
                    color: theme.themeColor.brandPrimaryRed,
                    width: 20,
                    height: 20,
                    fontSize: "12px",
                    fontWeight: "500"
                  }}>
                    X
                  </Avatar>
                </IconButton>
              </SubtaskRow>
            ))}
          </Stack>
        </FormControl>
      </Body>
      <Bottom>
        <Button
          id="create-flow-button"
          onClick={handleSubmit}
          disabled={subtasks.length === 0 || !category.length || loading}
          loading={loading}
          sx={{ maxWidth: '120px' }}
        >
          Update
        </Button>
        <a onClick={onCancel}>Cancel</a>
      </Bottom>
    </Modal>
  )
}

export default EditTaskFlow;