import React, { useState, useRef, useContext, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import { useQuery } from "@apollo/client";
import { MY_TASKS_QUERY } from "../../apollo/queries/myTasksQuery";
import { MANAGER_TASKS_QUERY } from "../../apollo/queries/managerTasksQuery";
import TableSkeleton from "../Skeleton/TableSkeleton";
import UserButton from './UserButton';
import CellLinethrough from './CellLinethrough';
import StandardGrid from "../Grid/Grid";
import TaskDescription from "./TaskDescription";
import TaskCreator from './TaskCreator';
import TaskAssigned from "./TaskAssigned";
import TableDateTime from "../TableDateTime/TableDateTime";
import IsBlocking from "./IsBlocking";
import OverdueDate from "./OverdueDate";
import { useTheme } from "@emotion/react";

const myColsDef = [
  {
    headerName: "Loan #",
    field: "loanNumber",
    sortable: true,
    // width: 200,
    cellRenderer: 'CellLinethrough'
  },
  {
    headerName: "Type",
    field: "processType",
    sortable: true,
    filter: true,
    // width: 200,
    cellRenderer: 'CellLinethrough',
  },
  {
    headerName: "Name",
    field: "taskName",
    sortable: true,
    filter: true,
    // width: 200,
    cellRenderer: 'CellLinethrough',
  },
  {
    headerName: "Dsc",
    field: "taskDescription",
    sortable: true,
    filter: true,
    // width: 100,
    cellRenderer: 'TaskDescription'
  },
  {
    headerName: "Due Date",
    field: "deadlineAt",
    sortable: true,
    filter: 'agDateColumnFilter',
    // width: 200,
    cellRenderer: 'TableDate',
  },
  {
    headerName: "Task Days Overdue",
    field: "taskDaysOverdue",
    sortable: true,
    // width: 200,
    filter: 'agNumberColumnFilter',
    cellRenderer: 'OverdueDate',
  },
  {
    headerName: "Creator",
    field: "ownerName",
    sortable: true,
    filter: true,
    // width: 200,
    cellRenderer: 'TaskCreator',
  },
  {
    headerName: "Assigned",
    field: "assignments",
    sortable: true,
    filter: true,
    // width: 250,
    cellRenderer: 'TaskAssigned',
    cellClass: "ag-show-dropdown-modal",
    valueFormatter: (params) => {
      return Array.isArray(params.value)
        ? params.value.map((a) => `${a.firstName} ${a.lastName}`).join(', ')
        : `${params.value.firstName} ${params.value.lastName}`;
    }
  },
  {
    headerName: "Blocking",
    field: "blocking",
    sortable: true,
    filter: true,
    // width: 200,
    cellRenderer: "IsBlocking"
  },
  {
    headerName: "Created",
    field: "dateCreated",
    sortable: true,
    filter: 'agDateColumnFilter',
    // width: 250,
    cellRenderer: 'TableDateTime',
  },
  {
    headerName: "Completed",
    field: "completed",
    filter: true,
    hide: true,
  }
];

const TableDate = ({ value, ...props }) => {
  if (!value) return <span />;
  const theme = useTheme();

  const datetime = value.toLocaleString();
  const [d] = datetime.split(', ');
  return <span {...props} style={{ color: theme.themeColor.bodyMain}}>{d}</span>
}

const components = {
  UserButton,
  CellLinethrough,
  TaskDescription,
  TaskCreator,
  TaskAssigned,
  TableDateTime,
  IsBlocking,
  OverdueDate,
  TableDate
};
const ls = window.localStorage;

export default function AssignedTasks({ selectedTab, screen, setNumTasks }) {
  const { user, portfolioID } = useContext(UserContext);
  const gridApiRef = useRef(null);

  const [tasks, setTasks] = useState([]);
  const [columnDefs, setColumnDefs] = useState(myColsDef);

  const { loading: managerLoading, data: managerTasks } = useQuery(MANAGER_TASKS_QUERY, {
    variables: { portfolioID },
    skip: user.userType !== "PORTFOLIO_MANAGER",
    pollInterval: parseInt(process.env.REACT_APP_BIG_POLL_INTERVAL),
    fetchPolicy: 'no-cache',
  });

  const { loading: tasksLoading, data: tasksData } = useQuery(MY_TASKS_QUERY, {
    variables: { userID: user.userID },
    skip: user.userType === "PORTFOLIO_MANAGER",
    pollInterval: parseInt(process.env.REACT_APP_BIG_POLL_INTERVAL),
    fetchPolicy: 'no-cache',
  });

  function onGridReady(params) {
    gridApiRef.current = params.api;
  }

  function onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  useEffect(() => {
    const visibleColumns = ls.getItem('assignedTasks') ? JSON.parse(ls.getItem('assignedTasks')) : [];
    const newCols = [...columnDefs].map((col) => {
      const found = visibleColumns.find((vc) => vc.colId === col.field);
      if (found) col.hide = found.hide;
      return col;
    });
    if (newCols) setColumnDefs(newCols);
  }, [selectedTab, screen]);

  useEffect(() => {
    if (managerTasks && user.userType === "PORTFOLIO_MANAGER") {
      const data = [...managerTasks.managerTasks.tasks, ...managerTasks.managerTasks.taskFlows];
      const sortedTasks = sortTasks(data);
      const transformed = transformTaskData([...sortedTasks]);
      setTasks(transformed)
      if (gridApiRef.current)
        gridApiRef.current.sizeColumnsToFit();
    } else if (tasksData && user.userType !== "PORTFOLIO_MANAGER") {
      const data = [...tasksData.myTasks.tasks, ...tasksData.myTasks.taskFlows];
      const sortedTasks = sortTasks(data);
      const transformed = transformTaskData([...sortedTasks]);
      setTasks(transformed)
      if (gridApiRef.current) gridApiRef.current.sizeColumnsToFit();
      if (setNumTasks) setNumTasks(transformed.length);
    }
  }, [tasksData, managerTasks])

  const gridOptions = {
    suppressPropertyNamesCheck: true,
    pagination: true,
    rowSelection: 'single',
    columnDefs,
  };

  function sortTasks(tasks) {
    const open = [];
    const completed = [];
    tasks.forEach((item) => {
      if (!item?.process?.deleted && item?.process?.asset?.activeProcess) {
        if (item.completedAt !== null) completed.push(item);
        else open.push(item);
      } else if (!item?.activity?.deleted) {
        if (item.completedAt !== null) completed.push(item);
        else open.push(item);
      }
    });

    const sortedOpen = open.sort((taskA, taskB) => new Date(taskA?.deadlineAt) - new Date(taskB?.deadlineAt));
    return sortedOpen.concat(completed);
  }

  function onCellClicked(e) {
    if (e.column.colId === 'assignments') return;
    if (e.column.colId === "taskActions") return;
    
    const asset = gridApiRef.current.getSelectedRows()[0];
    if (asset?.assetID) {
      const assetId = asset.assetID;
      const win = window.open(`/assets/${assetId}`, "_blank");
      win.focus();
    } 
  }

  function transformTaskData(data) {
    const getItemCompleted = (item, value) => {
      if (item.completedAt) return `cpl-${value}`

      return value;
    };
    const getTaskOwner = (item) => {
      if (item.owner) {
        return `${item.owner.firstName} ${item.owner.lastName}`
      } else if (item.task_flow?.owner) {
        return `${item.task_flow.owner.firstName} ${item.task_flow.owner.lastName}`
      }

      return ''
    };
    const getProcessType = (type) => {
      if (type === 'stateDefault' || type === 'judicial' || type === 'nonjudicial') {
        return "FORECLOSURE"
      } else if (type === 'chapter7' || type === 'chapter13' || type === 'chapter11') {
        return "BANKRUPTCY"
      } else if (type === 'LOSS_MITIGATION' || type === 'LOSS MITIGATION') {
        return "LOSS MITIGATION"
      } else if (type === 'other' || type === 'OTHER') {
        return "OTHER"
      } else return (type?.toUpperCase() || type) || "OTHER";
    };
    const getDaysOverdue = (item) => {
      if (!item) return 0;
      if (item.completedAt) return 0;

      const safeDate = (input) => (input === null || input === undefined) ? null : new Date(+input);
      let date = item.deadlineAt
        ? safeDate(item.deadlineAt)
        : safeDate(item?.dueDate);

      if (!date || date === 'Invalid Date') return '0';
      const today = new Date();
      const diffTime = today - date;
      return Math.floor(diffTime / (1000 * 60 * 60 * 24)) || 0;
    };
    const getDeadline = (item) => {
      if (!item.deadlineAt && !item.dueDate) return 0;
      return item.deadlineAt
        ? new Date(+item.deadlineAt)
        : new Date(+item.dueDate);
    };
    const getDateCreated = (item) => {
      if (item.createdAt) return new Date(+item.createdAt);
      if (item.startDate) return new Date(+item.startDate);
      return '';
    }

    return data.map((item) => ({
      loanNumber: getItemCompleted(item, item?.process?.asset?.loanNumber || item?.activity?.asset?.loanNumber),
      taskName: getItemCompleted(item, item.title || item.name),
      taskDescription: item.description,
      ownerName: getTaskOwner(item),
      dateCreated: getDateCreated(item),
      fullTask: item,
      assetID: item?.process?.asset?.id || item?.activity?.asset?.id,
      blocking: item.blocking,
      assignments: item.assignments,
      assignmentNames: item.assignments.map((a) => `${a.firstName} ${a.lastName}`).join(', '),
      deadlineAt: getDeadline(item),
      processType: getProcessType(item?.process?.processType || item.activity?.name),
      taskDaysOverdue: getDaysOverdue(item),
      completed: !!item.completedAt,
    }));
  }

  if (managerLoading || tasksLoading)
    return (
      <TableSkeleton columnDefs={myColsDef} />
    );

  return (
    <StandardGrid
      tableName="assignedTasks"
      onGridReady={onGridReady}
      rowData={tasks}
      onCellClicked={onCellClicked}
      gridOptions={gridOptions}
      onGridSizeChanged={onGridSizeChanged}
      components={components}
    />
  );
}