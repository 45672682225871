import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { TASKS_QUERY } from "../../apollo/queries/tasksQuery";
import { ACTIVITY_TASKS_QUERY } from "../../apollo/queries/activityTasksQuery";
import { TASK_FLOWS_QUERY } from "../../apollo/queries/taskFlowsQuery";
import { ACTIVITY_TASK_FLOWS_QUERY } from "../../apollo/queries/activityTaskFlowsQuery";
import { Accordion, AccordionSummary, CircularProgress, Box } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from "@emotion/react";
import Typography from "../../styled/Typography/Typography";
import { rgba } from "emotion-rgba";
import CustomProgress from "./CustomProgress";
import { MilestoneTask, MilestoneTaskFlow } from "./MilestoneTask";

const orderEvents = (data) => {
  if (!data) return false;
  const ongoing = [];
  const completed = [];

  data.forEach((item) => {
    if (item.completedAt !== null) completed.push(item);
    else ongoing.push(item);
  })

  const ongoingSorted = ongoing.sort((taskA, taskB) => new Date(taskA.deadlineAt) - new Date(taskB.deadlineAt));

  return { ongoing: ongoingSorted, completed }
}

const POLL_INTERVAL = parseInt(process.env.REACT_APP_SMALL_POLL_INTERVAL);

export default function MilestoneTasks({ assetID, process, readOnly, taskActivity, setAsset, setActiveProcess, setHasSentReferral, setScreen, activities, originalFC, client }) {
  const theme = useTheme();
  const processID = process?.id;
  const [isExpanded, setIsExpanded] = useState(true);

  const GET_TASKS = taskActivity ? ACTIVITY_TASKS_QUERY : TASKS_QUERY;
  const GET_TASK_FLOWS = taskActivity ? ACTIVITY_TASK_FLOWS_QUERY : TASK_FLOWS_QUERY;
  const QUERY_VARS = taskActivity ? { activityID: taskActivity?.id } : { processID };

  const { loading: loadingTasks, data: dataTasks, error: errorTasks } = useQuery(GET_TASKS, {
    variables: QUERY_VARS,
    pollInterval: POLL_INTERVAL,
  });

  const { loading: loadingTaskFlows, data: dataTaskFlows, error: errorTaskFlows } = useQuery(GET_TASK_FLOWS, {
    variables: QUERY_VARS,
    pollInterval: POLL_INTERVAL,
  });

  if (loadingTasks || loadingTaskFlows)
    return (
      <Accordion
        expanded={true}
        sx={{
          height: '40vh',
          backgroundColor: theme.themeColor.sectionFill,
          border: `1px solid ${theme.themeColor.sectionStroke}`,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: theme.themeColor.backgroundBody,
            borderBottom: `1px solid ${theme.themeColor.sectionStroke}`,
          }}
        />
        <Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <CircularProgress sx={{ marginTop: "35%" }} />
        </Box>
      </Accordion>
    );
  if (errorTasks || errorTaskFlows) return null;

  const data = [...dataTasks.tasks, ...dataTaskFlows.taskFlows];

  const { ongoing, completed } = orderEvents(data.filter(task => !task.deleted));

  return (
    <Accordion
      expanded={isExpanded}
      onClick={() => setIsExpanded(!isExpanded)}
      sx={{
        height: isExpanded ? '40vh' : 'auto',
        backgroundColor: theme.themeColor.sectionFill,
        border: `1px solid ${theme.themeColor.sectionStroke}`,
        overflowY: isExpanded ? 'auto' : 'none',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: theme.themeColor.backgroundBody,
          borderBottom: `1px solid ${theme.themeColor.sectionStroke}`,
        }}
      >
        <Box sx={{width: '90%', display: 'flex', justifyContent: 'space-between'}}>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <CustomProgress value={(1 - (ongoing.length / data.length)) * 100} isClosed={!isExpanded} />
            <Typography fontWeight="500" color={theme.themeColor.bodyMain}>Tasks</Typography>
          </Box>
          <Typography component="span" number> <span style={{
            fontWeight: 500,
            color: theme.themeColor.bodyMain
          }}>{ongoing.length}</span> <span style={{
            fontWeight: 500,
            color: rgba(theme.themeColor.bodyMain, 0.4),
          }}>/ {data.length}</span></Typography>
        </Box>
      </AccordionSummary>
      {
        ongoing && ongoing.map((task, i) => (
          <>
            {!task.subtasks && 
              <MilestoneTask className={`ongoingtask-${i}`} key={`${task.id}-${i}`}
                assetID={assetID}
                process={process}
                {...task}
                readOnly={readOnly} 
                taskActivity={taskActivity}
              />
            }
            {task.subtasks &&
              <MilestoneTaskFlow className={`ongoingtask-${i}`} key={`${task.id}-${i}`}
                assetID={assetID}
                process={process}
                {...task}
                readOnly={readOnly}
                taskActivity={taskActivity}
                setAsset={setAsset}
                setActiveProcess={setActiveProcess}
                setHasSentReferral={setHasSentReferral}
                setScreen={setScreen}
                activities={activities}
                originalFC={originalFC}
                client={client}
              />
            }
          </>
        ))
      }
      {
        completed && completed.map((task, i) => (
          <>
            {!task.subtasks &&
              <MilestoneTask className={`completedtask-${i}`} key={`completedtask-${i}`}
                assetID={assetID}
                process={process}
                {...task}
                readOnly={readOnly}
                taskActivity={taskActivity}
              />
            }
            {task.subtasks &&
              <MilestoneTaskFlow className={`completedtask-${i}`} key={`completedtask-${i}`}
                assetID={assetID}
                process={process}
                {...task}
                readOnly={readOnly}
                taskActivity={taskActivity}
                setActiveProcess={setActiveProcess}
                setHasSentReferral={setHasSentReferral}
                setScreen={setScreen}
                activities={activities}
                originalFC={originalFC}
                client={client}
              />
            }
          </>
        ))
      }
    </Accordion>
  )
}