import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, FormControl, MenuItem, Select, TextField, FormHelperText } from "@mui/material";
import { rgba } from "emotion-rgba";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { GET_DOCUMENT_TYPES } from "../../apollo/queries/documentTypes";
import Button from "../../styled/Button/Button";
import Drawer from "../../styled/Drawer/Drawer";
import Typography from "../../styled/Typography/Typography";
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_DOCUMENT_MUTATION } from "../../apollo/mutations/updateDocumentMutation";
import { MessageContext } from "../../context/MessageContext";
import { DOCUMENTS_BY_ASSET_QUERY } from "../../apollo/queries/documentsByAssetQuery";
import { CREATE_EVENT_MUTATION } from "../../apollo/mutations/createTaskAndEventsMutation";
import { EVENTS_QUERY } from "../../apollo/queries/eventsQuery";
import { AssetContext } from "../../context/AssetContext";

const Header = styled(Box)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
  height: 120px;
  color: ${({theme}) => theme.themeColor.bodySecondary};
  margin: 0 -25px;
  padding: 25px;
`

const Bottom = styled(Box)`
  display: flex;
  align-items: center;
  border-top: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
  height: 120px;
  background-color: ${({theme}) => theme.themeColor.sectionStroke};
  margin: 0 -25px;
  padding: 25px;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: space-around;
`

const initialData = {
  name: '',
  type: '',
  description: '',
}

function formatDocumentName(name) {
  const words = name.split('_')
  words[0] = words[0][0].toUpperCase() + words[0].slice(1).toLowerCase()
  return words.map(word => `${word} `)
}

const DocumentEdit = ({ showEdit, setShowEdit, data, processID }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(initialData);
  const [formError, setError] = useState('');
  const theme = useTheme();
  const { addMessage } = useContext(MessageContext);

  const { currentMilestone } = useContext(AssetContext);
  const bkMilestoneID = currentMilestone?.bk?.stepID;
  const fcMilestoneID = currentMilestone?.fc?.stepID;

  const res = useQuery(GET_DOCUMENT_TYPES);
  const [UpdateDocument, { loading: loadingUpdateDocument}] = useMutation(UPDATE_DOCUMENT_MUTATION, {
    refetchQueries: [
      { query: DOCUMENTS_BY_ASSET_QUERY, variables: { assetID: data.assetID } },
    ],
  });

  const [createEvent] = useMutation(CREATE_EVENT_MUTATION, {
    refetchQueries: [
      { query: EVENTS_QUERY, variables: { milestoneID: bkMilestoneID || fcMilestoneID, processID } },
    ]
  });

  useEffect(() => {
    setFormData({
      name: data.name,
      type: data.documentType,
      description: data.description,
    })
  }, [data]);

  useEffect(() => {
    setOpen(showEdit);
  }, [showEdit]);
  
  const onCancel = () => {
    setShowEdit(false);
  }

  const handleChange = (event) => {
    const {name, value} = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if (formData.name === '') {
      setError('The name must not be empty.');
      return;
    }

    const input = {
      where: {id: data.id},
      data: {
        name: formData.name,
        documentType: formData.type,
        description: formData.description,
      }
    }

    try {
      await UpdateDocument({
        variables: {input}
      });

      addMessage({ message: 'Document updated.'});

      await createEvent({ variables: { event: {
        type: 'document-updated',
        title: `Document updated`,
        description: `Updated document '${formData.name}'`,
        milestoneID: bkMilestoneID ? bkMilestoneID : fcMilestoneID,
        process: processID,
        createdDate: new Date(),
      }}});

    } catch(error) {
      console.log(error)
      addMessage({ message: 'Something went wrong. Try again later.'});
    }
  }

  return (
    <Drawer open={open}>
      <Header theme={theme}>
        <Typography fontSize="24px">Edit</Typography>
      </Header>
      <form onSubmit={onSubmit}>
        <Box sx={{mt: 2}}>
          {res.error && (
            <p>{res.error}</p>
          )}
          {!res.loading && !res.error && (
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <FormControl>
                <TextField
                  name="name"
                  variant='outlined'
                  label="Name"
                  onChange={handleChange}
                  value={formData.name}
                  id="name"
                />
                {formError && (
                  <FormHelperText>{formError}</FormHelperText>
                )}
              </FormControl>
              <FormControl sx={{mt: 3}}>
                <TextField
                  name="description"
                  multiline
                  maxRows={4}
                  variant='outlined'
                  label="Description"
                  id="description"
                  onChange={handleChange}
                  value={formData.description}
                />
              </FormControl>
              <FormControl sx={{mt: 3}}>
                <Select
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  id="type"
                >
                  {
                    res.data.getDocumentTypes.map((type) => {
                      return <MenuItem key={type.name} value={type.name}>{formatDocumentName(type.name)}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
        <Bottom>
          <Button type="submit" disabled={res.loading || loadingUpdateDocument || res.error} loading={loadingUpdateDocument} sx={{maxWidth: '120px'}}>Update</Button>
          <a onClick={onCancel}>Cancel</a>
        </Bottom>
      </form>
    </Drawer>
  );
}

export default DocumentEdit;