import React from "react";
import { TableContainer, Table, TableHead } from "@mui/material";
import TableHeader from "./TableHeader";
import TBody from "./TableBody";

export default function AssetImportTable({
  options,
  headersMappingOptions,
  onChangeMapping,
}) {
  function SortArray(x, y) {
    if (x.label === "loanNumber" || x.label === "state") {
      return -1;
    }
    else if (y.label === "loanNumber" || y.label === "state") {
      return 1
    }
    else if (x.label < y.label) return -1;
    else if (x.label > y.label) return 1;
    return 0;
  }

  const sortedSelectItems = [...headersMappingOptions].sort(SortArray);

  return (
    <TableContainer sx={{ maxHeight: 375, borderRadius: '6px' }}>
      <Table stickyHeader sx={{position: 'sticky'}}>
        <TableHead>
          <TableHeader />
        </TableHead>

        <TBody
          tableData={options}
          fieldOptions={sortedSelectItems}
          onChangeSelect={onChangeMapping}
        />
      </Table>
    </TableContainer>
  );
}
