import React, { useState, useContext } from "react";
import { Box, TextField, Button, Container, List, ListItem, ListItemIcon, ListItemText, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import ErrorPage from "../ErrorPage/ErrorPage";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useHistory, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { REGISTER_TEAM_USER } from "../../apollo/mutations/registerTeamUser";
import Drawer from "../../styled/Drawer/Drawer";
import Rating from "../../styled/Rating/Rating";
import { passwordStrength } from 'check-password-strength';
import { containsNumber, containsLowerCase, containsUpperCase, containsSpecialChars } from "../../utilities";
import styled from "@emotion/styled";
import { FaCheck } from "react-icons/fa";

export const GET_USER_INFO = gql`
  query GET_USER_INFO {
    getUserInfo {
      id
      firstName
      lastName
      userType
      tncStatus
      paymentMethodAdded
      email
      stripeInvoiceURL
      verifiedEmail
      staff
      watchlist
      managesTeam
      memberOfTeam
      teamType
      isBillingAdmin
    }
  }
`;

const INITIAL_ERROR_STATE = {
  firstName: false,
  lastName: false,
  password: false,
};

const errorMessages = {
  firstName: "Please provide a first name.",
  lastName: "Please provide a last name.",
  password: "Please provide a valid password.",
};

const ListIcon = styled(ListItemIcon)`
  min-width: 30px;
`

function RegisterLawyerForm() {
  const { setValue: setUserLocal } = useLocalStorage("user");
  const { inviteEmail, inviteToken, invitationID, firstName, lastName } = useParams();
  const { setUser } = useContext(UserContext);
  const INITIAL_FORM_STATE = {
    firstName: firstName || "",
    lastName: lastName || "",
    password: "",
  };

  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [formError, setFormError] = useState(INITIAL_ERROR_STATE);
  const [passwordStrengthValue, setPasswordStrengthValue] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();

  const [registerTeamUser, { error, loading }] = useMutation(REGISTER_TEAM_USER);

  const [getUserInfo, { loading: userInfoLoading, error: userInfoError }] =
    useLazyQuery(GET_USER_INFO);

  function formValidation() {
    let hasError = false;

    if (formData.firstName.length === 0) {
      setFormError((prevState) => ({ ...prevState, firstNameError: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, firstNameError: false }));
    }

    if (formData.lastName.length === 0) {
      setFormError((prevState) => ({ ...prevState, lastNameError: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, lastNameError: false }));
    }

    if (formData.password.length < 5) {
      setFormError((prevState) => ({ ...prevState, password: true }));
      hasError = true;
    } else {
      setFormError((prevState) => ({ ...prevState, password: false }));
    }

    return hasError;
  }

  async function handleSubmit(event) {
    let invEmail = decodeURI(inviteEmail)
    event.preventDefault();
    if (!formValidation() || invEmail.length === 0) {
      try {
        const registerResponse = await registerTeamUser({
          variables: {
            input: {
              email: invEmail,
              password: formData.password,
              firstName: formData.firstName,
              lastName: formData.lastName,
              invitationID: invitationID,
              inviteToken: inviteToken,
            },
          },
        });
        if (registerResponse) {
          const { data } = registerResponse;
          if (data) {
            setUserLocal({
              token: data.registerTeamUser.jwt,
              userID: data.registerTeamUser.user.id,
            });
            sessionStorage.setItem('refresh', data.registerTeamUser.refresh)
          }

          const userInfoResponse = await getUserInfo();

          setUser({
            token: data.registerTeamUser.jwt,
            userID: data.registerTeamUser.user.id,
            ...userInfoResponse.data.getUserInfo,
          });

          history.push("/");
        }
      } catch (error) {
        localStorage.removeItem('user');
        setFormError({ loginError: error });
      }
    }
  }

  function handleChange(event) {
    
    const {name, value} = event.target;

    if (name == "password") {
      if (value == "") setPasswordStrengthValue(0)
      else setPasswordStrengthValue(passwordStrength(value).id + 1)
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const { password, firstNameError, lastNameError } = formError;

  if (userInfoError) localStorage.removeItem('user');

  if (userInfoError) {
    return (
      <Container className='error-container' style={{ backgroundColor: "black" }}>
        <ErrorPage error={'The server has encountered an error while creating the user profile. Please refresh the page or try again later.'} />
      </Container>
    )
  }

  return (
    <Drawer open={true} width="23rem" height="89vh" hideBackdrop={true}>
      <div className="signup">
        <h2>Register Lawyer</h2>
        <form onSubmit={handleSubmit} data-testid="register-lawyer-form">
          {process.env.NODE_ENV === "development" && (
            <small className="mb-4 d-inline-block">
            You are running this application in <b>{process.env.NODE_ENV}</b>{" "}
            mode.
            </small>
          )}

          <Box
            sx={{ display: 'flex', flexWrap: 'wrap' }}
            spacing={2}
          >
            <TextField
              type="text"
              name="firstName"
              id="firstName"
              value={formData.firstName}
              onChange={handleChange}
              label="First Name"
              variant="outlined"
              fullWidth
              sx={{ m: 1 }}
              error={firstNameError}
              helperText={firstNameError ? errorMessages.firstName : ''}
            />

            <TextField
              type="text"
              name="lastName"
              id="lastName"
              value={formData.lastName}
              onChange={handleChange}
              label="Last Name"
              variant="outlined"
              fullWidth
              sx={{ m: 1 }}
              error={lastNameError}
              helperText={lastNameError ? errorMessages.lastName : ''}
            />

            <TextField
              type="email"
              name="email"
              id="email"
              value={decodeURI(inviteEmail)}
              onChange={handleChange}
              label="E-mail"
              variant="outlined"
              fullWidth
              sx={{ m: 1 }}
              InputProps={{
                readOnly: true,
              }}
            />

            <TextField
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              value={formData.password}
              onChange={handleChange}
              label="Password"
              variant="outlined"
              fullWidth
              sx={{ m: 1 }}
              error={password}
              helperText={password ? errorMessages.password : ''}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>,
              }}
            />

            <div className="password-strength">
              <span>Password Strength</span>
              <span> <Rating value={passwordStrengthValue} max={4}/> </span>
            </div>
          </Box>

          <div>
            <List dense={true}>
              <ListItem>
                <ListIcon sx={{minWidth: '30px'}}>
                  <FaCheck />
                </ListIcon>
                { formData.password.length > 8 ? 
                  <s><ListItemText
                    primary="Longer than 8 characters"
                  /></s>:
                  <ListItemText
                    primary="Longer than 8 characters"
                  />
                }
              </ListItem>
              <ListItem>
                <ListIcon>
                  <FaCheck />
                </ListIcon>
                { (containsLowerCase(formData.password) && containsUpperCase(formData.password)) ? 
                  <s><ListItemText
                    primary="Use both lowercase and uppercase letters"
                  /></s> : 
                  <ListItemText
                    primary="Use both lowercase and uppercase letters"
                  /> 
                }
              </ListItem>
              <ListItem>
                <ListIcon>
                  <FaCheck />
                </ListIcon>
                { containsSpecialChars(formData.password) ? 
                  <s><ListItemText
                    primary="Use special symbols"
                  /></s> : 
                  <ListItemText
                    primary="Use special symbols"
                  /> 
                }
              </ListItem>
              <ListItem>
                <ListIcon>
                  <FaCheck />
                </ListIcon>
                { containsNumber(formData.password) ?
                  <s><ListItemText
                    primary="Use numbers"
                  /></s>: 
                  <ListItemText
                    primary="Use numbers"
                  />
                }
              </ListItem>
            </List>
          </div>

          <div className="bottom-container">
            {error && (
              <ErrorMessage error={error.message} />
            )}

            <Button 
              disabled={loading || passwordStrengthValue !== 4}
              loading={loading || userInfoLoading}
              type="submit"
              variant="contained"
              sx={{ width: 200 }}
            >
            Accept Invite
            </Button>
          </div>
        </form>
      </div>
    </Drawer>
  )
}

export default RegisterLawyerForm;