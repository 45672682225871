import React, { useState, useCallback } from "react";
import { Modal as MUIModal, Backdrop, Fade, Box, Button as MUIButton } from "@mui/material";
import Typography from "../../styled/Typography/Typography";
import styled from "@emotion/styled";
import { rgba } from "emotion-rgba";

const StyledModal = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40vw;
  height: auto;
  border: 2px solid #E4E4E4;
  border-radius: 6px;
  boxShadow: 20;
  padding: 32px;
  background: #FFFFFF;
  outline: 0;
`

const StyledButton = styled(MUIButton)`
  width: 40%;
  margin-right: 10%;
  border-radius: 4px;
  line-height: 20px;
  color: #ffffff;
  background-color: #106AF1;
  font-family: IBM Plex Sans;

  &:hover {
    background: ${() => rgba("#106AF1", 0.8) }}
`

export default function ErrorNotifier({ children }) {
  const [doShowError, setDoShowError] = useState(false);
  const showError = useCallback(() => setDoShowError(true), []);

  const logoutUser = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  }

  return (
    <>
      {doShowError ? (
        <MUIModal
          open={true}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          sx={{
            backdropFilter: 'blur(8px)'
          }}
          data-testid="modal-styled"
        >
          <Fade in={true}>
            <StyledModal data-testid="modal-body">
              <Typography mb={5}>
                The server has encountered an error.
                Please refresh the page or try again later.
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <StyledButton onClick={() => window.location.reload()}>
                  Refresh
                </StyledButton>
                <MUIButton
                  variant="secondary"
                  sx={{border: 'none !important', background: 'inherit !important', boxShadow: 'none !important', width: 100}}
                  onClick={logoutUser}
                >
                  Logout
                </MUIButton>
              </Box>
            </StyledModal>
          </Fade>
        </MUIModal>
      ) : null}
      {children(showError)}
    </>
  );
}