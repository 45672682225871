import React, { useState, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { useMutation } from "@apollo/client";
import { CREATE_COMMENT_AND_EVENTS_MUTATION } from "../../../apollo/mutations/createCommentAndEventsMutation";
import { GET_ACTIVITY_EVENTS } from '../../../apollo/queries/getActivityEvents';
import { MessageContext } from '../../../context/MessageContext';
import { useTheme } from '@emotion/react';
import { AssetContext } from '../../../context/AssetContext';
import { ADD_MENTION_MUTATION } from '../../../apollo/mutations/addMentionMutation';
import MentionInput from '../../Mentions/MentionInput';
import { UserContext } from '../../../context/UserContext';

const AdornmentIcon = styled(KeyboardReturnIcon)`
  cursor: pointer;
  background-color: blue;
  height: 100%;
  width: 3%;
  padding-right: 5px;
  color: ${({ theme }) => theme.themeColor.bodyMain};
  background-color: ${({ theme }) => theme.themeColor.sectionFill};
`

export default function ActivityComment({ activity, readOnly }) {
  const theme = useTheme();
  const { addMessage } = useContext(MessageContext);
  const { user } = useContext(UserContext);
  const { portfolioUsers } = useContext(AssetContext);
  const [description, setDescription] = useState("");
  const [mentions, setMentions] = useState([]);
  const [error, setError] = useState(null);
  const [createComment] = useMutation(CREATE_COMMENT_AND_EVENTS_MUTATION, {
    refetchQueries: [
      { query: GET_ACTIVITY_EVENTS, variables: { activityID: activity.id } },
    ],
  });
  const [addMention] = useMutation(ADD_MENTION_MUTATION);

  function handleChange(newValue, newPlainText, mentions) {
    setDescription(newValue);
    setMentions(mentions);
    if (newValue.length > 0) setError(null);
  }

  async function handleSubmit() {
    if (description.length === 0) {
      setError("Text area cannot be blank.");
    } else {
      const comment = {
        activity: activity.id,
        description,
      };

      const event = {
        type: "comment",
        description,
        activity: activity.id,
        createdDate: new Date(),
      };

      try {
        const createdComment = await createComment({
          variables: {
            comment,
            event,
          },
        });

        if (mentions.length > 0) {
          mentions.forEach(async mention => {
            await addMention({
              variables: {
                input: {
                  commentId: createdComment.data.createComment.id,
                  mentionedUserId: mention.id,
                  mentionedByUserId: user.id,
                }
              }
            })
          });
        }
      } catch (error) {
        addMessage({ message: "Unable to create comment. Please try again or come back later.", type: "error" })
      }
      setError(null);
      setDescription("");
    }
  }

  useEffect(() => {
    if (error)
      addMessage({ message: error, type: "error"})
  }, [error]);

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  }

  return (
    <form onSubmit={onSubmit}>
      <MentionInput
        readOnly={readOnly}
        value={description}
        handleChange={handleChange}
        placeholder="Add a comment"
        options={portfolioUsers}
        inputProps={{
          endAdornment: 
            <AdornmentIcon onClick={handleSubmit} />,
          disableUnderline: true,
        }}
        variant='standard'
        sx={{
          width: '99.9%',
          borderTop: `2px solid ${theme.themeColor.bodySecondary}`,
          '& .MuiInputBase-input': {
            color: theme.themeColor.bodySecondary,
            backgroundColor: theme.themeColor.sectionFill,
            fontWeight: 500,
            lineHeight: '18px',
            fontSize: '12px',
            padding: '10px 15px',
            borderRadius: '0px',
          }
        }}
      />
    </form>
  )
}