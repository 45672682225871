import { gql } from "@apollo/client";

export const GET_TEAMS_BY_OWNER = gql`
  query FindTeamsByOwner($portfolioManager: ID) {
    findTeamsByOwner(portfolioManager: $portfolioManager) {
      id
      name
      type
      states
      invitations {
        status
      }

      assets {
        id
        loanNumber
      }

      memberships {
        id
        isBillingAdmin
        user {
          username
          firstName
          lastName
        }
        type
      }

      portfolios {
        id
        name
      }
    }
  }
`;
