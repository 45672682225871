import React, { useState, useContext } from "react";
import ActivityComment from "./ActivityComment";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import Events from "../../Events/Events";
import AddTaskAndHold from "../Milestones/AddTaskAndHold";
import { UserContext } from "../../../context/UserContext";
import { useTheme } from "@emotion/react";
import { rgba } from "emotion-rgba";
import Typography from "../../../styled/Typography/Typography";

const MilestonesBackground = styled('div')`
  position: absolute;
  width: 100%;
  height: 70vh;
  background: ${({theme}) => theme.themeColor.backgroundBody};
`
const TimelinePattern = styled('div')`
  position: absolute;
  background-color: transparent;  
  background: repeating-linear-gradient( 180deg, transparent, transparent 10px, ${({theme}) => rgba(theme.themeColor.bodyMain, 0.2)} 1px, ${({theme}) => rgba(theme.themeColor.bodyMain, 0.2)} 11px);
  height: 80%;
  width: 5px;
  z-index: 1;
  top: 100px;
`
const CustomBox = styled(Box)`
  padding-top: 80px;
  height: 80vh;
  width: 100%;
  display: flex;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
}
`
const FloatButton = styled('div')`
  position: absolute;
  bottom: 60px;
  left: 20px;
`
const TopContainer = styled(Box)`
  position: absolute;
  width: 100%;
  max-width: 100%;
  color: ${({ theme }) => theme.themeColor.bodyMain};
  background: ${({theme}) => `${rgba(theme.themeColor.sectionFill, 0.6)} !important`};
  backdrop-filter: blur(20px);
  padding: 4px 20px;
  z-index: 1;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
`
const ContainerText = styled(Typography)`
  font-size: 13px;
  line-height: 18px;
  color: ${({theme}) => theme.themeColor.bodySecondary};
`

const Activities = ({ activity, asset, activeProcess, readOnly }) => {
  const theme = useTheme();
  const { portfolioID } = useContext(UserContext);
  const [openTask, setOpenTask] = useState(false);
  const [addTaskFlow, setAddTaskFlow] = useState(false);

  return (
    <>
      <TopContainer theme={theme}>
        { activity.description
          ? <ContainerText theme={theme}>{activity.description}</ContainerText>
          : <ContainerText theme={theme}>
            This is a general, blank space that helps you track the asset and can be renamed from the settings icon to whatever fits your needs.
          </ContainerText>
        }
      </TopContainer>

      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CustomBox>
          <TimelinePattern theme={theme} />
          <MilestonesBackground theme={theme} />
          <Events
            milestoneID={null}
            processID={null}
            readOnly={false}
            activity={activity}
          />
          {
            !readOnly && (
              <FloatButton>
                <AddTaskAndHold 
                  asset={asset}
                  openTask={openTask}
                  setOpenTask={setOpenTask}
                  activity={activity}
                  addTaskFlow={addTaskFlow}
                  setAddTaskFlow={setAddTaskFlow}
                  portfolioID={portfolioID}
                  processType='OTHER'
                  activeProcess={activeProcess}
                />
              </FloatButton>
            )
          }

        </CustomBox>
        <ActivityComment readOnly={readOnly} activity={activity} />
      </div>
    </>
  )
}

export default Activities;