import React from "react";

const UserManual = () => {
  return (
    <iframe src="2025.03_Jonah_Direct_User_Manual.pdf"
      title="User Manual"
      style={{
        height: "80vh",
        width: "60vw",
        margin: "auto",
        border: ".5px solid gray",
        borderRadius: "5px",
        backgroundColor: "white",
      }}
    />
  )
}

export default UserManual;