import React, { useState, useContext } from "react";
import differenceInDays from "date-fns/differenceInDays";
import { UserContext } from "../context/UserContext";
import { useParams } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_PORTFOLIOS } from "../apollo/queries/getPortfolios";
import { ASSET_QUERY } from "../apollo/queries/assetQuery";
import { Box, Tab, Tabs, Menu, MenuItem, CircularProgress } from "@mui/material";
import { FaExpandAlt } from "react-icons/fa";
import { FiMinimize2 } from "react-icons/fi";
import styled from "@emotion/styled";
import { rgba } from "emotion-rgba";
import { useTheme } from "@emotion/react";
import Typography from "../styled/Typography/Typography";
import { isForeclosure, isBankruptcy } from "../utilities";
import { setActiveMilestoneStatus, getMilestones } from "../utilities/getMilestones";
import getMilestoneStatistics from "../utilities/getMilestoneStatistics.js";
import DetailSidebar from "../components/AssetDetail/DetailsSidebar/DetailsSidebar";
import TasksAndHolds from "../components/TasksAndHolds/TasksAndHolds";
import SettingsIcon from '@mui/icons-material/Settings';
import Processes from "../components/AssetDetail/Processes";
import ProcessDrawer from "../components/AssetDetail/DetailsSidebar/ProcessDrawer";
import CloseProcess from "../components/Modals/CloseProcess";
import UpdateAssetWatchlist from "../components/Modals/UpdateAssetWatchlist";
import { useEffect } from "react";
import EditActivity from "../components/AssetDetail/Activities/EditActivity.jsx";
import { AssetContext } from "../context/AssetContext.js";
import { formatPortfolioUsers } from "../utilities/portfolioUsers.js";
import { GET_USERS_BY_PORTFOLIO_AND_TEAM } from "../apollo/queries/getUsersByPortfolioAndTeam.js";
import { GET_TEAMS } from "../apollo/queries/getTeams.js";
import { MilestoneTokenContext } from "../context/MilestoneTokenContext.js";
import { GET_MILESTONE_CLOSED_TOKEN } from "../apollo/queries/getMilestoneClosedToken.js";
import SoftDeleteArchivedLoan from "../components/Modals/SoftDeleteArchivedLoan";
import ReferAssetToLawFirm from "../components/Modals/ReferAssetToLawFirm.jsx";

export const getActiveStep = (substeps) => {
  if (!substeps || !substeps.length) return;
  const ml = substeps.find((step) => (step.status === "active"));
  return ml;
}
const getCurrMilestone = (fcI, fcM, bkI, bkM) => {
  if ((!fcM || !fcI) && (!bkI || !bkM)) return;
  if (!bkI) {
    // Regular FC
    const fc = fcM.find((ml) => ml.stepID === fcI);
    return { fc };
  } else if (bkI) {
    // BK exists
    const fc = fcM ? fcM.find((ml) => ml?.stepID === fcI) : null;
    const bk = bkM.find((ml) => ml.stepID === bkI);
    return { fc, bk }
  }
}

const AssetDetailContainer = styled(Box)`
  display: grid;
  height: 89vh;
  grid-template-columns: 1.2fr 4fr;
`
const Container = styled(Box)`
  display: flex;
  margin-top: 10px;
`
const AssetContainer = styled(Box)`
  width: ${({isFullScreen}) => isFullScreen ? '98vw' : '80%'};
  background-color: ${({theme}) => `${theme.themeColor.backgroundBody} !important`};
  border-radius: 4px;
  border: 1px solid ${({theme}) => theme.themeColor.sectionStroke};
  margin: 0 10px;
  background-color: ${({theme}) => `${rgba(theme.themeColor.sectionFill, 0.6)} !important`};
  backdrop-filter: blur(20px);
`
const AssetBox = styled(Box)`
  height: 95%;
`
const TaskAndHoldContainer = styled(Box)`
  width: 40%;
  margin: 0 auto;
`
const IconContainer = styled('div')`
  max-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 0 15px;
`
const StyledSettingsIcon = styled(SettingsIcon)`
  color: ${({ theme }) => rgba(theme.themeColor.bodyMain, 0.4)};
  cursor: pointer;
`
const StyledTab = styled(Tab)`
  min-width: 150px;
  width: 250px;
`
const ExpandDiv = styled('div')`
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export default function AssetDetails({ client }) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [asset, setAsset] = useState(null);
  const [hasSentReferral, setHasSentReferral] = useState(false);
  const { assetID } = useParams();
  const [activeProcess, setActiveProcess] = useState(null);
  
  const { user, portfolioID, teamID, setPortfolioID, setUser } = useContext(UserContext);
  const { setMilestoneToken, milestoneToken } = useContext(MilestoneTokenContext);
  const { 
    setAsset: setAssetContext, 
    setPortfolioUsers, 
    setHasOpenHolds, 
    setHasBlockingTasks,
    setFcTldrContent,
    setFcTldrCount,
    setBkTldrContent,
    setBkTldrCount
  } = useContext(AssetContext);

  const [get_portfolios] = useLazyQuery(GET_PORTFOLIOS);
  const [getPortfolioUsers] = useLazyQuery(GET_USERS_BY_PORTFOLIO_AND_TEAM);
  const [getPortfolioTeams] = useLazyQuery(GET_TEAMS);

  const res = useQuery(ASSET_QUERY, {
    variables: { assetID, userID: user.userID },
    fetchPolicy: 'no-cache',
  });

  const { data: milestoneTokenData } = useQuery(GET_MILESTONE_CLOSED_TOKEN, {
    variables: { assetID },
  })

  const getPortfolio = async () => {
    const portfolios = await get_portfolios();
    if (portfolios?.data?.getUserPortfolios?.length > 0) {
      setPortfolioID(portfolios.data.getUserPortfolios[0].id);
      await client.refetchQueries({ include: "active" });
    } else {
      setUser(null);
    }
  };

  useEffect(() => {
    if (!portfolioID) {
      getPortfolio();
    }
    if (res?.data?.asset) {
      setAsset(res.data.asset);
      setAssetContext(res.data.asset);

      if (res.data.asset?.activeProcess) {
        const activeProcess = res.data.asset.activeProcess;
        if (activeProcess.tldr) {
          if (isForeclosure(activeProcess)) {
            setFcTldrContent(activeProcess.tldr);
            setFcTldrCount(activeProcess.tldr.length);
          } else if (isBankruptcy(activeProcess)) {
            setBkTldrContent(activeProcess.tldr);
            setBkTldrCount(activeProcess.tldr.length);

          }
        }
      }
      
      // Only sets new activeProcess if its not null / empty due to some bug with Apollo
      if (!activeProcess && res.data.asset?.activeProcess) {
        if (isBankruptcy(res.data.asset?.activeProcess) && res.data.asset?.activeProcess.stepID === "6.0") setHasSentReferral(false)
        else if (activeProcess?.referral) setHasSentReferral(true)
        else if (res.data.asset?.activeProcess.referral && res.data.asset?.activeProcess?.deleted) setHasSentReferral(false)
        else setHasSentReferral(!!res.data.asset?.activeProcess?.referral)
      
        setActiveProcess(res.data.asset.activeProcess || activeProcess);
        const hasOpenHolds = res.data.asset.activeProcess?.holds?.some((hold) => !hold?.closeDate && !hold?.deleted && hold?.holdType !== "BK");
        setHasOpenHolds(hasOpenHolds);

        const hasBlockingTasks = res.data.asset.activeProcess?.tasks?.some((task) => task?.blocking && !task?.deleted && !task?.completedAt)
          || res.data.asset.activeProcess?.task_flows?.some((task) => task?.blocking && !task?.completedAt);
        setHasBlockingTasks(hasBlockingTasks);
      }
    }

    if (milestoneTokenData?.getMilestoneClosedTokens) {
      setMilestoneToken(milestoneTokenData.getMilestoneClosedTokens[0]);
    }
  }, [res, activeProcess, milestoneTokenData, setFcTldrContent, setFcTldrCount, setBkTldrContent, setBkTldrCount]);

  useEffect(() => {
    const effect = async () => {
      if (res?.data?.asset) {
        const portfolioUsers = await getPortfolioUsers({
          variables: { portfolioID, teamID, assetID: res.data.asset.id },
        });

        const teamData = await getPortfolioTeams({
          variables: { input: { portfolioId: portfolioID } },
        });

        if (portfolioUsers?.data?.getUsersByPortfolioAndTeam?.length > 0 && teamData?.data?.findTeams) {
          setPortfolioUsers(formatPortfolioUsers(teamData.data.findTeams, portfolioUsers.data.getUsersByPortfolioAndTeam, res.data.asset, user));
        }
      }
    }

    effect();
  }, [res]);

  if (!asset) return <CircularProgress />

  if (asset && asset.deleted) {
    return (
      <div style={{height:"51vw", display: 'flex', flexDirection: 'column', justifyContent:'center'}}>
        <center><Typography component="h1" variant="h2">This asset has been deleted.</Typography></center>
      </div>
    )
  }

  const statusValue = activeProcess?.stepID || asset?.statusValue;
  let hasBK, bkSteps, bkMilestones, originalFC;
  let fcSteps, fcMilestones;

  if (asset && activeProcess && !activeProcess.deleted) {
    // If the active process is a BK, check FCs for an open BK hold
    if (isBankruptcy(activeProcess)) {
      hasBK = activeProcess.deleted || activeProcess.closeDate ? false : true;

      asset.processes.forEach((process) => {
        if (!process.deleted && isForeclosure(process)) {
          const hasBkHold = process.holds.some((hold) => 
            hold.holdType === "BK" ? true : false
          );
          if (hasBkHold) originalFC = process;
          else if (asset.isProperty) originalFC = process;
        }
      })
      bkSteps = activeProcess.processSteps;
      bkMilestones = setActiveMilestoneStatus(bkSteps, statusValue);

      fcSteps = originalFC?.processSteps;
      fcMilestones = originalFC
        ? setActiveMilestoneStatus(fcSteps, originalFC.stepID)
        : setActiveMilestoneStatus(fcSteps, activeProcess.stepID);
    } else {
      // Current activeProcess is FC, but might have completedBK hold
      let hasBKHold;
      asset.processes.forEach((process) => {
        if (isBankruptcy(process) && process.status !== "MANUALLY_CLOSED") {
          hasBKHold = process;
        }
      })
      if (hasBKHold) {
        hasBK = hasBKHold;
        originalFC = activeProcess;
        bkSteps = hasBKHold.processSteps;
        bkMilestones = setActiveMilestoneStatus(bkSteps, hasBKHold.stepID)
      }

      fcSteps = activeProcess?.processSteps;
      fcMilestones = setActiveMilestoneStatus(fcSteps, statusValue)
    }
  } else {
    // For AssetReferral
    fcMilestones = getMilestones(asset.state, statusValue)
  }

  // DetailsSidebar info depends on what the currently active process is
  const { nextStepStats, sideBarStats } = hasBK
    ? getMilestoneStatistics(bkMilestones, res.data)
    : getMilestoneStatistics(fcMilestones, res.data)

  // Used to calculate the current, active milestone for each process
  const bkActiveStepIndex = bkMilestones && getActiveStep(bkMilestones)?.stepID;
  const fcActiveStepIndex = fcMilestones && getActiveStep(fcMilestones)?.stepID;

  return (
    <AssetDetailContainer>
      {!isFullScreen && (<DetailSidebar asset={asset} activeProcess={activeProcess} sideBarStats={sideBarStats} nextStepStats={nextStepStats} client={client} />)}
      <Asset
        nextStepStats={nextStepStats}
        sideBarStats={sideBarStats}
        asset={asset}
        client={client}
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
        hasSentReferral={hasSentReferral}
        bkMilestones={bkMilestones}
        originalFC={originalFC}
        fcMilestones={fcMilestones}
        user={user}
        portfolioID={portfolioID}
        bkActiveStepIndex={bkActiveStepIndex}
        fcActiveStepIndex={fcActiveStepIndex}
        hasBK={hasBK}
        activities={asset.activities}
        activeProcess={activeProcess}
        setActiveProcess={setActiveProcess}
        setAsset={setAsset}
        setHasSentReferral={setHasSentReferral}
        milestoneToken={milestoneToken}
      />
    </AssetDetailContainer>
  );
}

const getProcessType = (activeProcess, hasSentReferral) => {
  if (!hasSentReferral || !activeProcess || activeProcess?.deleted)
    return "Process";
  else if (
    activeProcess.processType === "stateDefault" ||
    activeProcess.processType === "judicial" ||
    activeProcess.processType === "nonJudicial"
  ) return "Foreclosure";
  else if (
    activeProcess.processType === "chapter7" ||
    activeProcess.processType === "chapter13" ||
    activeProcess.processType === "chapter11"
  ) return "Bankruptcy";
}

const Asset = ({
  asset,
  client,
  isFullScreen,
  setIsFullScreen,
  bkMilestones,
  originalFC,
  fcMilestones,
  nextStepStats,
  sideBarStats,
  bkActiveStepIndex,
  fcActiveStepIndex,
  hasSentReferral,
  user,
  hasBK,
  activities,
  activeProcess,
  setActiveProcess,
  setAsset,
  setHasSentReferral,
  milestoneToken
}) => {
  const { 
    setCurrentMilestone, 
    setSelectedProcess, 
    setActiveMilestoneID
  } = useContext(AssetContext);

  const startScreen = hasBK && isBankruptcy(activeProcess) && originalFC
    ? activities.length+2
    : activities.length+1;
  const [screen, setScreen] = useState(startScreen);
  const [openProcessDrawer, setOpenProcessDrawer] = useState(false);
  const [openCloseProcess, setopenCloseProcess] = useState(false);
  const [openEditActivity, setOpenEditActivity] = useState(false);
  const [openUpdateWatchlist, setOpenUpdateWatchlist] = useState(false);
  const [openSoftDelete, setOpenSoftDelete] = useState(false);
  const [openReReferral, setOpenReReferral] = useState(false);
  const [settingsAnchor, setSettingsAnchor] = useState(null);
  const [taskAndHoldProcess, setTaskAndHoldProcess] = useState(activeProcess);
  const [taskActivity, setTaskActivity] = useState(activities[0]);
  const openSettings = Boolean(settingsAnchor);
  const [taskAndHoldMilestone, setTaskAndHoldMilestone] = useState(null)
  const theme = useTheme();
  useEffect(() => {
    const currMilestone = getCurrMilestone(fcActiveStepIndex, fcMilestones, bkActiveStepIndex, bkMilestones);
    setCurrentMilestone(currMilestone);
  }, [asset]);

  useEffect(() => {
    if (screen === activities.length+1 && hasSentReferral) {
      if (!hasBK && !originalFC) { // Regular FC
        setTaskAndHoldProcess(activeProcess);
        setTaskAndHoldMilestone(activeProcess.stepID);
        setActiveMilestoneID(activeProcess.stepID);
        setTaskActivity(null);
        setSelectedProcess(activeProcess);
        // No need to update TLDR here - it's already loaded from the proper state
      }
      else if (originalFC && hasSentReferral) { // FC with Open BK Hold
        setTaskAndHoldProcess(originalFC);
        setTaskAndHoldMilestone(originalFC.stepID);
        setActiveMilestoneID(originalFC.stepID);
        setTaskActivity(null);
        setSelectedProcess(originalFC);
        // No need to update TLDR here - it's already loaded from the proper state
      }
      else if (!originalFC && hasBK) { // Regular BK
        setTaskAndHoldProcess(activeProcess);
        setTaskAndHoldMilestone(activeProcess.stepID);
        setActiveMilestoneID(activeProcess.stepID);
        setTaskActivity(null);
        setSelectedProcess(activeProcess);
        // No need to update TLDR here - it's already loaded from the proper state
      }
    } else if (screen === activities.length+2 && hasSentReferral) {
      if (isForeclosure(activeProcess) && hasBK) { // Closed BK if opened as hold
        setTaskAndHoldProcess(hasBK);
        setTaskAndHoldMilestone(hasBK.stepID);
        setTaskActivity(null);
        setSelectedProcess(hasBK);
        // No need to update TLDR here - it's already loaded from the proper state
      }
      else if (originalFC && hasBK) { // Active BK if opened as hold by FC
        setTaskAndHoldProcess(activeProcess);
        setTaskAndHoldMilestone(activeProcess.stepID);
        setTaskActivity(null);
        setSelectedProcess(activeProcess);
        // No need to update TLDR here - it's already loaded from the proper state
      }
    } else if (screen === activities.length+1 && !hasSentReferral) {
      setTaskAndHoldProcess(null);
      setTaskActivity(null);
      setTaskAndHoldMilestone(null);
      setSelectedProcess(null);
      // We don't need to clear TLDR here - it's already handled by the TLDR component
    } else if (screen <= activities.length) {
      setTaskAndHoldProcess(null);
      setTaskActivity(activities[0]);
      setTaskAndHoldMilestone(null);
      setSelectedProcess(null);
      // We don't need to clear TLDR here - it's already handled by the TLDR component
    }
  }, [screen, activeProcess, activities, hasBK, hasSentReferral, originalFC, setActiveMilestoneID, setSelectedProcess]);

  const handleChange = (event, newValue) => {
    setScreen(newValue);

    // TODO: set new selected milestone
    if (hasBK && isBankruptcy(activeProcess) && originalFC) {
      if (newValue === activities.length+2) {
        setSelectedProcess(hasBK);
        // No need to update TLDR here - TLDR component uses the right state automatically
      } else if (newValue === activities.length+1) {
        setSelectedProcess(originalFC);
        // No need to update TLDR here - TLDR component uses the right state automatically
      }
    }
  };
  const settingsClick = (event) => {
    setSettingsAnchor(event.currentTarget);
  };
  const onSettingsClose = () => {
    setSettingsAnchor(null);
  }
  const clickChangeProcess = () => {
    setOpenProcessDrawer(true);
    onSettingsClose();
  }
  const clickCloseProcess = () => {
    if (isForeclosure(activeProcess) && fcActiveStepIndex === "6.0")
      return;
    else if (isBankruptcy(activeProcess) && bkActiveStepIndex === "6.0")
      return;
    setopenCloseProcess(true);
    onSettingsClose();
  }
  const clickEditActivity = () => {
    setOpenEditActivity(true);
    onSettingsClose();
  }
  const clickUpdateWatchlist = () => {
    setOpenUpdateWatchlist(true);
    onSettingsClose();
  }

  const clickSoftDelete = () => {
    setOpenSoftDelete(true);
    onSettingsClose();
  }
  
  const clickReRefer = () => {
    setOpenReReferral(true);
    onSettingsClose();
  }

  const showSettings = () => {
    if (bkActiveStepIndex === "6.0" && fcActiveStepIndex === "6.0")
      return false;
    else return true;
  }

  const canAccessSettings = showSettings();
  const canCloseProcess = activeProcess
    ? (isForeclosure(activeProcess) && fcActiveStepIndex !== "6.0") ||
      (isBankruptcy(activeProcess) && bkActiveStepIndex !== "6.0")
    : false;

  const freeArchiveExpDate = asset?.freeArchiveExpiryDate && new Date(+asset.freeArchiveExpiryDate);
  const canDeleteArchivedLoan = freeArchiveExpDate && !!(differenceInDays(new Date(), freeArchiveExpDate) < 0);

  return (
    <Container theme={theme}>
      <AssetContainer isFullScreen={isFullScreen}>
        <Tabs
          value={screen}
          onChange={handleChange}
          sx={{ display: 'flex' }}
        >
          <ExpandDiv onClick={() => setIsFullScreen(!isFullScreen)}>
            {isFullScreen ? <FiMinimize2 /> : <FaExpandAlt />}
          </ExpandDiv>
          {activities.length && 
            activities.map((activity, i) => (<StyledTab label={activity.name || "General Activity"} key={activity-i}/>))
          }
          <StyledTab selected label={originalFC ? "Foreclosure" : getProcessType(activeProcess, hasSentReferral)} />
          {(originalFC && (!originalFC.closeDate && !originalFC.deleted) && (!activeProcess.closeDate && !activeProcess.closeDate)) && <StyledTab label="Bankruptcy" />}
          <StyledTab label="Documents" />
          {canAccessSettings &&
            <IconContainer>
              <StyledSettingsIcon onClick={settingsClick}/>
              <Menu
                anchorEl={settingsAnchor}
                open={openSettings}
                onClose={onSettingsClose}
              >
                {((user.userType === "PORTFOLIO_MANAGER") && screen <= activities.length) &&
                  <MenuItem onClick={clickEditActivity}>
                    Edit Activity
                  </MenuItem>
                }
                <MenuItem onClick={clickUpdateWatchlist}>
                  { user?.watchlist?.includes(asset.id) ? "Remove from Watchlist" : "Add to Watchlist" }
                </MenuItem>

                {(isForeclosure(activeProcess) && !activeProcess?.closeDate && screen > activities.length && !milestoneToken) &&
                  <MenuItem onClick={clickChangeProcess}>
                    Change Process Type
                  </MenuItem>}
                {(user.userType === "PORTFOLIO_MANAGER" && canCloseProcess && screen > activities.length && !milestoneToken) &&
                  <MenuItem onClick={clickCloseProcess}>
                    Close Process
                  </MenuItem>}
                {(user.userType === "PORTFOLIO_MANAGER" && !milestoneToken && canDeleteArchivedLoan) &&
                  <MenuItem onClick={clickSoftDelete}>
                    Delete Archived Asset
                  </MenuItem>}
                {(user.userType === "PORTFOLIO_MANAGER" && !activeProcess?.closeDate && activeProcess?.referral?.deleted && screen > activities.length && !milestoneToken) &&
                  <MenuItem onClick={clickReRefer}
                  >
                    Re-Refer Asset
                  </MenuItem>
                }
              </Menu>
            </IconContainer>
          }
        </Tabs>
        <AssetBox>
          <div style={{position: 'relative', height: '100%', display: "flex"}}>
            <Processes
              screen={screen}
              setScreen={setScreen}
              asset={asset}
              originalFC={originalFC}
              nextStepStats={nextStepStats}
              sideBarStats={sideBarStats}
              fcMilestones={fcMilestones}
              fcActive={fcActiveStepIndex}
              bkMilestones={bkMilestones}
              bkActive={bkActiveStepIndex}
              client={client}
              setIsFullScreen={setIsFullScreen}
              hasSentReferral={hasSentReferral}
              hasBK={hasBK}
              activities={activities}
              activeProcess={activeProcess}
              setActiveProcess={setActiveProcess}
              setAsset={setAsset}
            />
          </div>
        </AssetBox>
      </AssetContainer>
      {!isFullScreen && (
        <TaskAndHoldContainer>
          <TasksAndHolds
            asset={asset}
            activities={activities}
            process={taskAndHoldProcess}
            taskActivity={taskActivity}
            setAsset={setAsset}
            setActiveProcess={setActiveProcess}
            setHasSentReferral={setHasSentReferral}
            screen={screen}
            setScreen={setScreen}
            style={{width: '25%'}}
            originalFC={originalFC}
            client={client}
          />
        </TaskAndHoldContainer>
      )}
      <>
        <UpdateAssetWatchlist 
          open={openUpdateWatchlist}
          onClose={() => setOpenUpdateWatchlist(false)}
          user={user}
          assets={[asset]}
        />
        <ProcessDrawer
          open={openProcessDrawer}
          onClose={() => setOpenProcessDrawer(false)}
          asset={asset}
          activeProcess={activeProcess}
          setActiveProcess={setActiveProcess}
          setAsset={setAsset}
        />
        <CloseProcess
          open={openCloseProcess}
          onClose={() => setopenCloseProcess(false)}
          asset={asset}
          user={user}
          milestoneID={taskAndHoldMilestone}
          process={taskAndHoldProcess}
          originalFC={originalFC}
          setScreen={setScreen}
          client={client}
          setActiveProcess={setActiveProcess}
          setAsset={setAsset}
          setHasSentReferral={setHasSentReferral}
        />
        <EditActivity
          open={openEditActivity}
          onClose={() => setOpenEditActivity(false)}
          activity={activities[0]}
        />
        <SoftDeleteArchivedLoan 
          open={openSoftDelete}
          onClose={() => setOpenSoftDelete(false)}
          asset={asset}
        />
        <ReferAssetToLawFirm
          open={openReReferral}
          onClose={() => setOpenReReferral(false)}
          assets={[asset]}
          setActiveProcess={setActiveProcess}
          setAsset={setAsset}
        />
      </>
    </Container>
  )
};
