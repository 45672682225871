import React, { useState, useContext } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { Container, CircularProgress } from "@mui/material"
import { PREANALYZE_IMPORT_FILE } from "../apollo/mutations/preanalyzeImportFile";
import { CHECK_FILE_TYPES } from "../apollo/mutations/checkFileTypes";
import { IMPORT_ITEMS } from "../apollo/mutations/importItems";
import AssetDataMapper from "../components/AssetDataMapper/AssetDataMapper";
import AnalyzeAssetsImport from "../components/AnalyzeAssetsImport/AnalyzeAssetsImport";
import useContentTypes from "../hooks/useContentTypes";
import PreviewImportTable from "../components/PreviewImportTable/PreviewImportTable";
import { MessageContext } from '../context/MessageContext';
import { UserContext } from "../context/UserContext";
import { AssetImportContext } from "../context/AssetImportContext";
import MissingAssets from "../components/MissingAssets/MissingAssets";
import ConflictViewer from "../components/MissingAssets/ConflictViewer";
import ProcessingScreen from "../components/ProcessingScreen/ProcessingScreen";
import FirstAccessImportContent from "../components/Modals/FirstAccessImportContent";
import { useMutation } from "@apollo/client";

const IMPORT_ERROR_STATUS = 'Import error';

const convertToHashTable = (arr, key) => {
  return arr.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});
};

export default function AssetImport({ firstImport, onClose, client, assetRefetch }) {
  const history = useHistory();
  const { addMessage } = useContext(MessageContext);
  const { contentTypes, loading: contentTypesLoading } = useContentTypes();
  const { portfolioID } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [conflictResolution, setConflictResolution] = useState("keep_old");
  // This is used only when user resolves manually to let the backend know what to do with each asset
  const [manualResolution, setManualResolution] = useState({});
  const [dataToSend, setDataToSend] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [assetConflicts, setAssetConflicts] = useState([]);

  const [preanalyzeImportFile] = useMutation(PREANALYZE_IMPORT_FILE);
  const [checkFileTypes] = useMutation(CHECK_FILE_TYPES);
  const [importItems] = useMutation(IMPORT_ITEMS);

  const {
    file,
    data,
    analysis,
    target,
    setFile, 
    setData,
    setAnalysis,
    setTarget,
    mappedFields,
    setNanFields
  } = useContext(AssetImportContext);

  const analyzeImports = async (event) => {
    event.preventDefault();
    const dataToSend = {
      source: "upload",
      options: {
        filename: file.filename,
      },
      data,
      type: file.type,
    };

    // Send Request
    try {
      setIsLoading(true);
      const response = await preanalyzeImportFile({
        variables: { input: dataToSend }
      });
      const { parsedData, fieldInfo } = response.data.preAnalyzeImportFile;

      const nansFromFile = Object.entries(parsedData[0]).map(([key, value]) => {
        const nan = /\$.*|.%|[1]st|[2]nd|[3]rd|[0-9]th/;
        const address = /[1]st[" "]|[2]nd[" "]|[3]rd[" "]|([0-9]th)[" "]/g;
        if (nan.test(value) && !address.test(value))
          return key
      }).filter((val) => val !== undefined);

      setNanFields(nansFromFile);

      const fieldsInfo = convertToHashTable(fieldInfo.fieldStats, "fieldName");
      const fieldsHeader = fieldInfo.fieldStats;
      const fileTypeRes = await checkFileTypes({
        variables: { fieldsInfo, parsedData }
      });
      const fileTypeErrors = fileTypeRes.data.checkFileTypes;

      setTarget(contentTypes.schema);
      setAnalysis({ fieldsInfo, parsedData, fieldsHeader, fieldInfo, fileTypeErrors });
      
      return history.push({
        pathname: "/import-assets/preview",
        state: { firstImport }
      });

    } catch (error) {
      addMessage({ message: "The server has encountered an error while trying to preview the contents of this file. Please try again.", type: "error" })
    } finally {
      setIsLoading(false);
    }
  };

  const removeFile = () => {
    history.push("/");
    setAnalysis(null);
    setTarget(null);
    setData("");
    setFile(null);
  };

  // Data Upload and Import
  const uploadDataAndImport = async (event, firstImportt = false, data) => {
    try {
      setIsUploading(true);
      const d = firstImportt ? data : dataToSend;
      const cr = {"conflictResolution": firstImportt? "update" : conflictResolution};

      const responseData = await importItems({
        variables: {
          input: {
            ...d,
            ...cr,
            manualResolution,
            assetConflicts,
            mappedFields
          }
        }
      })

      if (responseData && !responseData.error /*&& !(responseData.data.importItems.status === IMPORT_ERROR_STATUS)*/) {
        setIsUploading(false);
        removeFile();
        addMessage({ message: "Asset import has completed successfully." });
        await client.refetchQueries({ include: "active" });
        history.push("/");
      } else if (responseData.data.importItems.response.error) {
        addMessage({ message: `${IMPORT_ERROR_STATUS}: ${responseData.data.importItems.response.error}`, type: "error", time:false });
        setIsUploading(false);
      } else {
        setIsUploading(false);
        removeFile();
        addMessage({ message: `The server has encountered a problem while trying to import content. ${responseData.message}`, type: "error", time:5000 })
        history.push("/");
      }
    } catch (error) {
      removeFile();
      setIsUploading(false);
      addMessage({ message: "The server has encountered an error while trying to import content. Please try again later.", type: "error" });
      history.push("/");
    }
  };

  if (contentTypesLoading)
    return <CircularProgress />;

  if (isUploading) return <ProcessingScreen open={isUploading} />
  
  return (
    <Container className="asset-import" sx={{mt: 5, mb: 5}}>
      {(!analysis && !firstImport) && (
        <AnalyzeAssetsImport
          analyzeImports={analyzeImports}
          isLoading={isLoading}
          setFile={setFile}
          setData={setData}
          file={file}
        />
      )}
      {(!analysis && firstImport) && (
        <FirstAccessImportContent
          onClose={onClose}
          analysis={analysis}
          analyzeImports={analyzeImports}
          isLoading={isLoading}
          setFile={setFile}
          setData={setData}
          file={file}
          removeFile={removeFile}
          assetRefetch={assetRefetch}
        />
      )}
      {analysis && (
        <Switch>
          <Route
            path="/import-assets/preview"
            render={({location}) => (
              <PreviewImportTable
                analysis={analysis}
                setAnalysis={setAnalysis}
                setTarget={setTarget}
                setData={setData}
                setFile={setFile}
                onContinueLink={"/import-assets/map"}
                firstImport={location.state.firstImport}
              />
            )}
          />
          <Route
            path="/import-assets/map"
            render={({location}) => (
              <AssetDataMapper
                analysis={analysis}
                target={target}
                file={file}
                data={data}
                removeFile={removeFile}
                onContinueLink={"/import-assets/missingAssets"}
                setDataToSend={setDataToSend}
                onFinish={uploadDataAndImport}
                firstImport={location.state.firstImport}
              />
            )}
          />
          <Route
            path="/import-assets/missingAssets"
            render={() => (
              <MissingAssets
                data={data}
                setConflictResolution={setConflictResolution}
                setManualResolution={setManualResolution}
                onContinueLink={"/import-assets/conflicts"}
                removeFile={removeFile}
                portfolioID={portfolioID}
              />
            )}
          />
          <Route
            path="/import-assets/conflicts"
            render={() => (
              <ConflictViewer 
                data={data}
                conflictResolution={conflictResolution}
                manualResolution={manualResolution}
                onFinish={uploadDataAndImport}
                setAssetConflicts={setAssetConflicts}
                removeFile={removeFile}
                fieldMapping={dataToSend.fieldMapping}
                portfolioID={portfolioID}
              />
            )}
          />
        </Switch>
      )}
    </Container>
  );
}
