import React, { createContext, useEffect, useState } from 'react';

export const UserContext = createContext(null)

export default function UserProvider({ children }) {
  const [user, setUser] = useState(() => {
    const localData = localStorage.getItem("user");
    return localData !== null ? JSON.parse(localData) : null;
  });

  const [portfolioID, setPortfolioID] = useState(() => {
    const localData = localStorage.getItem("portfolioID");
    return localData !== null ? JSON.parse(localData) : null;
  });

  const [teamID, setTeamID] = useState(() => {
    const localData = localStorage.getItem("teamID");
    return localData !== null ? JSON.parse(localData) : null;
  });

  const [portfolioName, setPortfolioName] = useState(() => {
    const localData = localStorage.getItem("portfolioName");
    return localData !== null ? JSON.parse(localData) : null;
  });

  useEffect(() => {
    if (user) window.localStorage.setItem("user", JSON.stringify(user))
    else {
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("portfolioID");
      window.localStorage.removeItem("teamID");
      window.localStorage.removeItem("portfolioName");
    }
    
    if (portfolioID) window.localStorage.setItem('portfolioID', JSON.stringify(portfolioID))
    if (teamID) window.localStorage.setItem('teamID', JSON.stringify(teamID))
  }, [user, portfolioID, teamID, portfolioName])

  return <UserContext.Provider value={{ user, setUser, portfolioID, setPortfolioID, teamID, setTeamID, portfolioName, setPortfolioName }}>
    {children}
  </UserContext.Provider>
}
