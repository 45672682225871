import React, { useEffect, useContext } from 'react';
import { Box, Button, Badge, InputAdornment, TextField } from '@mui/material';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { rgba } from 'emotion-rgba';
import Typography from '../../styled/Typography/Typography';
import { gql, useMutation } from '@apollo/client';
import { EVENTS_QUERY } from '../../apollo/queries/eventsQuery';
import { AssetContext } from '../../context/AssetContext';
import { isForeclosure, isBankruptcy } from '../../utilities';

const StyledContainer = styled(Box)`
  position: absolute;
  top: 59px;
  width: 100%;
  max-width: 100%;
  color: ${({ theme }) => theme.themeColor.bodyMain};
  background: ${({theme}) => `${rgba(theme.themeColor.sectionFill, 0.6)} !important`};
  backdrop-filter: blur(20px);
  padding: 4px 20px;
  z-index: 1;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};

  &::-webkit-scrollbar {
    display: none;
  };
`;

const StyledButton = styled(Button)`
  width: 200px;
  margin-left: 5px;
  background-color: transparent;
`;

const TLDRInput = styled(TextField)`
  width: 100%;
  font-size: 12px;
  backdrop-filter: blur(20px);
  
  & .MuiInputBase-input {
    background-color: ${({theme}) => `${rgba(theme.themeColor.sectionFill, 0.6)} !important`};
    color: ${({theme}) => theme.themeColor.bodyMain}; /* Ensure text is visible */
  }
    
  & .MuiInputBase-root {
    background-color: ${({theme}) => `${rgba(theme.themeColor.sectionFill, 0.6)} !important`};
  }

  & .MuiInputAdornment-root {
    color: ${({theme}) => `${rgba(theme.themeColor.oppositeBody, 0.6)} !important`};
  }
`;

const UPDATE_PROCESS_MUTATION = gql`
  mutation UpdateProcess($updateProcessInput: updateProcessInput!, $createEventInput: createEventInput!) {
    updateProcess(input: $updateProcessInput) {
      id
    }
    createEvent(input: $createEventInput) {
      id
    }
  }
`;


export default function TLDR({
  openFilter,
  numFiltersChanged,
  process,
  readOnly
}) {
  const theme = useTheme();
  const { 
    fcTldrContent, setFcTldrContent, fcTldrCount, setFcTldrCount,
    bkTldrContent, setBkTldrContent, bkTldrCount, setBkTldrCount,
    activeMilestoneID
  } = useContext(AssetContext);

  const isFC = isForeclosure(process);
  const isBK = isBankruptcy(process);

  const tldrContent = isFC ? fcTldrContent : (isBK ? bkTldrContent : '');
  const setTldrContent = isFC ? setFcTldrContent : (isBK ? setBkTldrContent : setFcTldrContent);
  const tldrCount = isFC ? fcTldrCount : (isBK ? bkTldrCount : 0);
  const setTldrCount = isFC ? setFcTldrCount : (isBK ? setBkTldrCount : setFcTldrCount);

  const [originalTldr, setOriginalTldr] = React.useState('');

  const [updateProcess] = useMutation(UPDATE_PROCESS_MUTATION, {
    refetchQueries: [
      { query: EVENTS_QUERY, variables: { milestoneID: activeMilestoneID, processID: process.id } },
    ],
  });

  useEffect(() => {
    if (process) {
      const processTldr = process.tldr || '';

      if (processTldr) {
        setTldrContent(processTldr);
        setOriginalTldr(processTldr);
        setTldrCount(processTldr.length);
      } else {
        setTldrContent('');
        setOriginalTldr('');
        setTldrCount(0);
      }

      if (isFC && processTldr !== fcTldrContent) {
        setFcTldrContent(processTldr);
        setFcTldrCount(processTldr.length);
      } else if (isBK && processTldr !== bkTldrContent) {
        setBkTldrContent(processTldr);
        setBkTldrCount(processTldr.length);
      }
    }
  }, [process, isFC, isBK]);

  const handleChange = (ev) => {
    if (ev.target.value.length > 200) {
      return;
    }

    setTldrContent(ev.target.value);
    setTldrCount(ev.target.value.length);
  }

  const handleSave = async () => {
    if (tldrContent && tldrContent.length > 200)
      return;

    if (tldrContent === originalTldr)
      return;

    const trimmedContent = tldrContent ? tldrContent.trim() : '';

    const eventData = {
      type: 'tldr-update',
      milestoneID: activeMilestoneID,
      process: process.id,
      createdDate: new Date(),
      description: trimmedContent
    }

    try {
      await updateProcess({
        variables: {
          createEventInput: eventData,
          updateProcessInput: {
            where: {
              id: process.id
            },
            data: {
              tldr: trimmedContent
            }
          }
        }
      });

      setOriginalTldr(trimmedContent);

      if (isFC) {
        setFcTldrContent(trimmedContent);
        setFcTldrCount(trimmedContent.length);
      } else if (isBK) {
        setBkTldrContent(trimmedContent);
        setBkTldrCount(trimmedContent.length);
      }

      if (process) {
        process.tldr = trimmedContent;
      }
    } catch (error) {
      console.error("Error updating TLDR:", error.message);
    }
  }

  return (
    <form 
      onSubmit={(e) => {
        e.preventDefault();
        handleSave();
      }}
    >
      <StyledContainer theme={theme}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <TLDRInput
            disabled={readOnly}
            theme={theme}
            variant="standard"
            placeholder="Add a summary or important note here."
            multiline
            maxRows={2}
            value={tldrContent || ''}
            onChange={handleChange}
            onBlur={handleSave}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSave();
              }
            }}
            InputProps={{
              startAdornment: <InputAdornment position="end" sx={{ mr: 1 }}>
                <Typography><b>TLDR</b></Typography>
              </InputAdornment>,
              endAdornment: <InputAdornment position="end" sx={{ mr: 1 }}>
                <Typography>
                  <span style={{ fontSize: '8px' }}>{tldrCount}/200</span>
                </Typography>
              </InputAdornment>,
            }}
          />
        </Box>

        <StyledButton
          onClick={() => openFilter(true)}
        >
          FILTER TIMELINE
          <Badge sx={{ml: 2, fontFamily: 'IBM Plex Mono'}} badgeContent={numFiltersChanged} color="secondary" />
        </StyledButton>
      </StyledContainer>
    </form>
  )
}