import React, { createContext, useState } from "react";

export const AssetContext = createContext(null);

export default function AssetProvider({ children }) {
  // Current asset open in the page and list of users and teams with access to it
  const [asset, setAsset] = useState(null);
  const [portfolioUsers, setPortfolioUsers] = useState([]);

  // Current milestone that the asset process is in
  const [currentMilestone, setCurrentMilestone] = useState(null);
  const [activeMilestoneID, setActiveMilestoneID] = useState(null);

  const [hasOpenHolds, setHasOpenHolds] = useState(false);
  const [hasBlockingTasks, setHasBlockingTasks] = useState(false);

  // TLDR state that persists across tab changes - separate for each process type
  const [fcTldrContent, setFcTldrContent] = useState('');
  const [fcTldrCount, setFcTldrCount] = useState(0);
  // Bankruptcy TLDR content and character count
  const [bkTldrContent, setBkTldrContent] = useState('');
  const [bkTldrCount, setBkTldrCount] = useState(0);
  
  const [selectedProcess, setSelectedProcess] = useState(null);
  return (
    <AssetContext.Provider value={{ 
      asset,
      setAsset,
      portfolioUsers,
      setPortfolioUsers,
      currentMilestone,
      setCurrentMilestone,
      selectedProcess,
      setSelectedProcess,
      activeMilestoneID,
      setActiveMilestoneID,
      hasOpenHolds,
      setHasOpenHolds,
      hasBlockingTasks,
      setHasBlockingTasks,
      fcTldrContent,
      setFcTldrContent,
      fcTldrCount,
      setFcTldrCount,
      bkTldrContent,
      setBkTldrContent,
      bkTldrCount,
      setBkTldrCount,
    }}>
      {children}
    </AssetContext.Provider>
  )
}