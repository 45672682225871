import React from "react";
import { MentionsTextField } from '@yurialessandrom/mui-mentions-rr';
import { useTheme } from "@emotion/react";
import { rgba } from "emotion-rgba";

const MentionInput = ({
  value,
  variant='outlined',
  handleChange,
  inputProps = {},
  options = [],
  label,
  placeholder,
  multiline,
  rows=1,
  sx={},
  error,
  disabled=false,
  readOnly
}) => {
  const theme = useTheme();
  return (
    <MentionsTextField
      value={value}
      variant={variant}
      label={label}
      placeholder={placeholder}
      fullWidth
      multiline={multiline}
      defaultValue={value}
      zIndex={999}
      rows={rows}
      error={error}
      InputProps={inputProps}
      onChange={handleChange}
      disabled={readOnly || disabled}
      highlightColor={rgba(theme.themeColor.brandPrimaryBlue, 0.05)}
      sx={sx}
      dataSources={[
        {
          data: options.filter(option => option.__typename === 'User').map(option => ({id: option.id, display: `${option.firstName} ${option.lastName}`})),
        },
      ]}
    />
  )
}

export default MentionInput;