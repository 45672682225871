import React, { useContext } from 'react';
import PortfolioAssets from '../../pages/PortfolioAssets';
import TeamAssets from '../../pages/TeamAssets';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import ErrorPage from '../ErrorPage/ErrorPage';

export default function SwitchUserType({ client }) {
  const { user } = useContext(UserContext);

  if (!user) return <ErrorPage error="You are not authorized to view this page." />;
  if (user.tncStatus !== true) return <Redirect to='/terms-and-conditions' />

  // Check if payment method is required
  const requiresPayment = process.env.REACT_APP_USE_ONLY_WITH_CC === "true" && 
    ((user.userType === "PORTFOLIO_MANAGER" && !user.teamType) || 
    (user.teamType === "LAWFIRM" && user.isBillingAdmin === true)) && 
    user.paymentMethodAdded !== true;

  if (requiresPayment) return <Redirect to="/payment" />;
  
  if (user.userType === "PORTFOLIO_MANAGER" || user.userType === "PORTFOLIO_MEMBER") return <PortfolioAssets client={client} />;
  if (user.userType === "TEAM_MANAGER" || user.userType === "TEAM_MEMBER") return <TeamAssets client={client} />;

  return <ErrorPage error="You are not authorized to view this page." />;
}
