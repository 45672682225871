import React, { useState, useContext, useEffect } from "react";
import Modal from "../../styled/Modal/Modal";
import { Box } from "@mui/material";
import Button from '@mui/material/Button';
import { useMutation } from "@apollo/client";
import { GET_IMPORT_CONFLICTS } from "../../apollo/mutations/importConflicts";
import { AssetImportContext } from "../../context/AssetImportContext";
import { MessageContext } from "../../context/MessageContext";
import { useTheme } from "@emotion/react";
import Typography from "../../styled/Typography/Typography";

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useHistory } from "react-router-dom";

export default function ConflictViewer({ data, conflictResolution, manualResolution, onFinish, setAssetConflicts, removeFile, fieldMapping, portfolioID }) {
  const theme = useTheme();
  const { addMessage } = useContext(MessageContext);
  const { mappedFields } = useContext(AssetImportContext);
  const [showModal, setShowModal] = useState(true);
  const [conflicts, setConflicts] = useState([]);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const history = useHistory();

  const [getAssetConflicts] = useMutation(GET_IMPORT_CONFLICTS);

  const onModalClose = () => {
    setShowModal(false); 
    removeFile();
  }

  useEffect(() => {
    getConflicts();
  }, [])

  function removeNullProperties(obj) {
    let newObj = {};
    Object.keys(obj).forEach(key => {
      if (obj[key] !== null && obj[key] !== undefined) {
        newObj[key] = obj[key];
      } else if (Array.isArray(obj[key])) {
        // Keep the array as is, including null values
        newObj[key] = obj[key];
      }
    });
    if (newObj.__typename)
      delete newObj.__typename;
    return newObj;
  }

  const getConflicts = async () => {
    const dataToSend = {
      source: "upload",
      options: {
        mappedFields
      },
      data,
      type: "text/csv",
      conflictResolution,
      manualResolution,
      fieldMapping,
      portfolioID
    };
    try {
      const response = await getAssetConflicts({
        variables: {
          input: dataToSend
        }
      });
      const responseData = response.data.conflicts.map((item) => removeNullProperties(item));
      setConflicts(responseData);
      setAssetConflicts(responseData)
      if (responseData.length >= 1)
        setSelectedLoan(responseData[0].id);
    } catch (error) {
      addMessage({ message: "The server has encountered an error while trying to resolve the conflicts. Please try again.", type: "error" });
      history.push("/");
    }
  }

  return (
    <Modal open={showModal} width='50vw'>
      { conflicts.length == 0 ? 
        <>
          <Typography variant="h4" component="h2" mt={3} data-testid="conflicts">No conflicts exist</Typography>
          <p>You may proceed to importing your data</p>
        </> :
        <>
          <Typography variant="h4" component="h2" mt={3} data-testid="conflicts">{conflicts.length} Conflicts</Typography>
          <p>Are you sure you want to proceed?</p>
          <InputLabel id="demo-simple-select-label">Loan</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            value={selectedLoan}
            label="Loan"
            onChange={(e) => setSelectedLoan(e.target.value)}
            sx={{width:'100%', mb:2, color:'black'}}
          >
            { conflicts.map(item => (
              <MenuItem value={item.id} key={item.id}>{item.id}</MenuItem>
            ))}
          </Select>
          <Box>
            <TableContainer sx={{maxHeight: 450}} theme={theme}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{backgroundColor: 'black', color: 'white'}}>Value</TableCell>
                    <TableCell sx={{backgroundColor: 'black', color: 'white'}}>Old</TableCell>
                    <TableCell sx={{backgroundColor: 'black', color: 'white'}}>New</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {conflicts.filter(co => co.id == selectedLoan).map((obj) => (
                    Object.keys(obj).filter(co => co !== "id" && co !== 'propertyID').map(co => 
                      <TableRow
                        key={obj.co}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {co}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {obj[co][0]? String(obj[co][0]) : obj[co][0]}
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{color:'red'}}>
                          {obj[co][1]? String(obj[co][1]) : obj[co][1]}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box> 
        </>
      }
      <Box sx={{display: 'flex', mt: 5}}>
        <Button 
          onClick={(e) => onFinish(e, false)}
          variant="contained"
        >
          Finish
        </Button>
        <Button onClick={onModalClose}>
          Cancel
        </Button>
      </Box>
    </Modal>
  )
}