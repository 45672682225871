import React, { useState, useContext, useEffect } from 'react';
import { Tabs, Box, Tab } from '@mui/material';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { UserContext } from '../context/UserContext';
import UserSettings from '../components/UserSettings/UserSettings';
import DataAndLegal from '../components/DataAndLegal/DataAndLegal';
import SubscriptionSettings from '../components/SubscriptionSettings/SubscriptionSettings';
import JonahDirectDigest from '../components/JonahDirectDigest/JonahDirectDigest';
import Notifications from '../components/Notifications/Notifications';
import { rgba } from "emotion-rgba";
import APIKeys from '../components/APIKeys/APIKeys';

const StyledPageContainer = styled(Box)`
  background: ${({theme}) => rgba(theme.themeColor.sectionFill, 0.6)};
  border: 1px solid ${({theme}) => theme.themeColor.sectionStroke};
  backdrop-filter: blur(20px);
  border-radius: 4px;
  margin: 11px;
`

const SettingsContainer = styled(Box)`
  background-color: ${({theme}) => theme.themeColor.sectionFill};
`

export default function AccountSettings() {
  const [screen, setScreen] = useState(0);
  const { user } = useContext(UserContext);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setScreen(newValue);
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const tabIndex = parseInt(hash.replace('#tab', ''), 10) - 1;
      if (!isNaN(tabIndex) && tabIndex >= 0) {
        setScreen(tabIndex);
      }
    }
  }, []);

  // Calculate tab indices based on user type
  const getTabContent = () => {
    const content = [
      { label: "Account", component: <UserSettings user={user}/> },
      { label: "Notifications", component: <Notifications /> },
      { label: "Data & Legal", component: <DataAndLegal /> },
      { label: "Activity Digest", component: <JonahDirectDigest user={user}/> }
    ];

    if (user.userType === 'PORTFOLIO_MANAGER') {
      content.push({ label: "API Keys", component: <APIKeys /> });
    }

    if (user.userType === 'PORTFOLIO_MANAGER' || 
        (user.userType === 'TEAM_MANAGER' && user.teamType === 'LAWFIRM' && user.isBillingAdmin)) {
      content.push({ label: "Subscription", component: <SubscriptionSettings user={user} /> });
    }

    return content;
  };

  const tabContent = getTabContent();

  return (
    <StyledPageContainer>
      <Tabs
        theme={theme}
        value={screen}
        onChange={handleChange}
      >
        {tabContent.map((tab, index) => (
          <Tab 
            key={index}
            theme={theme} 
            label={tab.label}
            sx={tab.label === "Data & Legal" || tab.label === "Activity Digest" ? 
              { width: "125px", minWidth: "125px" } : undefined}
          />
        ))}
      </Tabs>
      <SettingsContainer theme={theme}>
        <Box sx={{ p: 3 }}>
          {tabContent[screen]?.component}
        </Box>
      </SettingsContainer>
    </StyledPageContainer>
  );
}
